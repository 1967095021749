import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import styles from "./formSelect.module.scss";
import FormInput from "../formInput/formInput";

export interface CountryType {
  name: string;
  dial_code: string;
  code: string;
}

interface FormSelectProps {
  title?: string;
  options: CountryType[];
  label?: string;
  name?: string;
  isClear?: boolean;
  singleSelect?: boolean;
  onChange?: (name: string, value: CountryType) => void;
  value: CountryType;
}

export const CountrySelect = (props: FormSelectProps) => {
  const [value, setValue] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [countryOptions, setCountryOptions] = useState<CountryType[]>(
    props.options
  );

  const dropRef = useRef<any>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (props.isClear) {
      setValue([]);
    }

    return () => {};
  }, [props.isClear]);

  useEffect(() => {
    setValue([props.value]);
  }, [props.value]);

  const handleFocus = (e: any) => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: CountryType) => {
    let _val = [...value];
    if (!props.singleSelect) {
      let i = _val.indexOf(option);
      if (i === -1) {
        _val.push(option);
      } else {
        _val.splice(i, 1);
      }
    } else {
      _val = [option];
      setIsOpen(false);
    }
    // setValue(_val);
    setCountryOptions(props.options);
    setSearch("")
    props.onChange && props.onChange(props.name ?? "", _val[0]);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value?.toLowerCase() ?? "";
    if (!val) {
      setCountryOptions(props.options);
    } else {
      let _options: CountryType[] = props.options.filter(
        (x) =>
          x.name.toLowerCase().includes(val) ||
          x.dial_code.includes(val) ||
          x.code.toLowerCase().includes(val)
      );
      setCountryOptions(_options);
    }
    setSearch(val);
  };

  return (
    <div className={styles.formSelect}>
      <div
        ref={dropRef}
        className={`${styles.formSelectWrapper} ${styles.countrySelect}`}
      >
        <button
          className={`${styles.selectBtn}`}
          // onFocus={handleFocus}
          onClick={handleFocus}
        >
          {value.length !== 0 ? (
            value.map((val, index) => (
              <span
                className={`${styles.val} ${styles.countryVal}`}
                key={index}
              >
                <span
                  className={`ip2location-flag-16 flag-${
                    val.code?.toLowerCase() ?? ""
                  }`}
                ></span>{" "}
                <span style={{ paddingLeft: "0.25rem" }}>{val.dial_code} </span>
              </span>
            ))
          ) : (
            <span className={styles.placeholder}>{props.label}</span>
          )}
        </button>
        <div
          style={{ width: 320 }}
          className={`${styles.optionsWrapper} ${isOpen && styles.show}`}
        >
          <div className={styles.options}>
            <FormInput
              autoFocus
              value={search}
              onChange={handleSearch}
              placeholder="Search"
              className={styles.search}
            />
            {countryOptions.map((option, index) => {
              return (
                <button
                  className={`${styles.link}`}
                  onClick={() => selectOption(option)}
                  key={index}
                >
                  <span>
                    {option.dial_code} ({option.name})
                  </span>
                  {value.includes(option) && (
                    <span className={styles.check}>
                      <FaRegCheckCircle />
                    </span>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountrySelect;
