/* eslint-disable react/jsx-no-target-blank */
import { FOData } from "../../util/staticData/foManageData";
import ManagementSection from "./components/ManagementSection/ManagementSection";
import styles from "./PageFixedOddsManage.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {
  ASSETS_URL_IMG,
  ASSETS_URL_VIDEO,
} from "../../util/constants/generalConstants";
import { Helmet } from "react-helmet";
import StaticManagementSection from "./components/ManagementSection/staticManagementSection";
import { Link } from "react-router-dom";
import AnimatedButton from "../../components/headers/Buttons/AnimatedButton/AnimatedButton";
import ashvaIcon from "../../assets/images/ashvaIcon.svg";
import NewAnimatedButton from "../../components/headers/Buttons/AnimatedButton/newAnimatedButton/newAnimatedButton";
export interface PageFixedOddsManageProps {}

export const PageFixedOddsManage = () => {
  const HeadSection = (
    <div className={styles.headSection}>
      <h2>ASHVA PLATFORM AS SERVICE</h2>
      <AnimationOnScroll animateIn="animate__zoomIn">
        <div className={styles.headDescription}>
          {/* <div className={styles.imgDescriptionWrp}> */}
          <img
            style={{ margin: "1rem" }}
            src={ASSETS_URL_IMG + "ashva_racing.png"}
            alt="Harness"
          />
          Ashva platform is running on{" "}
          <span className={"highlight"}>cloud infrastructure</span>{" "}
          and as an advantage, we can offer this{" "}
          <span className={"highlight"}>platform as a service</span>. This means
          you don't need to manage{" "}
          <span className={"highlight"}>costly infrastructure</span>,{" "}
          <span className={"highlight"}>servers</span>, or{" "}
          <span className={"highlight"}>developers</span>. Our development team
          will integrate a{" "}
          <span className={"highlight"}>white-labeled racing interface</span>{" "}
          into your environment. Our support team will assist you{" "}
          <span className={"highlight"}>24x7 with priority service</span>. With
          this, you still will have{" "}
          <span className={"highlight"}>full control over your strategies</span>
          , <span className={"highlight"}>fixed odds</span>,{" "}
          <span className={"highlight"}>customers</span>,{" "}
          <span className={"highlight"}>books</span>, and{" "}
          <span className={"highlight"}>privacy</span>. We are also very
          flexible to <span className={"highlight"}>integrate</span>{" "}
          any kind of third party tools for{" "}
          <span className={"highlight"}>video streaming, CRM and CMS</span>.
          {/* </div> */}
        </div>
      </AnimationOnScroll>
    </div>
  );

  return (
    <div className={styles.PageFixedOddsManage}>
      <Helmet>
        <title>Fixed Odds Management Service - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/fixed-odds-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className={styles.videoWrapper}>
        <video
          autoPlay
          muted
          loop
          className={styles.video}
          src={ASSETS_URL_VIDEO + "runningHorses.mp4"}
        >
          <source
            src={ASSETS_URL_VIDEO + "runningHorses.mp4"}
            type="video/mp4"
          />
        </video>
      </div>

      <div className={styles.pageWrapper}>
        {HeadSection}
        {/* <AnimatedButton
          Link={"https://demo.ashva.app/"}
          label={"Checkout Ashva Racing"}
          buttonIcon={ashvaIcon}
          edges={"ashva"}
          buttonColor={"rgb(38 42 83)"}
        /> */}
        <div className={styles.buttonDiv}>
          <a
            href={"https://demo.ashva.app/"}
            target={"_blank"}
            className={styles.btnLink}
            rel="noreferrer"
          >
            <img
              src={ASSETS_URL_IMG + "checkout_ashva_racing.gif"}
              alt={"ashvaButton"}
              className={styles.buttonImage}
            />
          </a>
        </div>

        <StaticManagementSection />
      </div>
    </div>
  );
};

export default PageFixedOddsManage;
