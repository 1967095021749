import styles from "./HomePage.module.scss";
import ExploreText from "./Components/ExploreText/ExploreText";
import PzoneVideo from "./Components/PzoneVideo/PzoneVideo";
import WhoWeAre from "./Components/WhoWeAre/WhoWeAre";
import FeaturesGrid from "./Components/FeaturesGrid/FeaturesGrid";
import WhatWeDo from "./Components/WhatWeDo/WhatWeDo";
import { FaArrowDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const [isExploreVisible, setIsExploreVisible] = useState(false);

  useEffect(() => {
    const handleScroll = (e: any) => {
      let top = window.scrollY;

      top < 200 ? setIsExploreVisible(false) : setIsExploreVisible(true);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollDown = () => {
    window.scrollTo({
      behavior: "auto",
      top: window.innerHeight - 70,
      left: 0,
    });
  };

  return (
    <section className={styles.pageWrapper}>
      <Helmet>
        <title> Protocol Zone - The Zone Of Excellence </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/data-analytic-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className={styles.mainViewWrapper}>
        <div className={styles.infoWrapper}>
          <div className={styles.exploreWrapper}>
            <ExploreText />
          </div>
          <div className={styles.videoWrapper}>
            <AnimationOnScroll animateIn="animate__zoomIn">
              <PzoneVideo />
            </AnimationOnScroll>  
          </div>
        </div>

        <div
          className={`${styles.exploreBtnWrapper} ${
            isExploreVisible && styles.hide
          }`}
        >
          <button onClick={scrollDown}>
            Explore
            <FaArrowDown className={styles.icon} />
          </button>
        </div>
      </section>

      <section className={styles.whoWeAreWrapper}>
        <WhoWeAre />
      </section>
      <section className={styles.features}>
        <FeaturesGrid />
      </section>
      <section id="whatWeDo" className={styles.whatWeDeoWrapper}>
        <WhatWeDo />
      </section>
    </section>
  );
};

export default HomePage;
