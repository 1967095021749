import { FC, useEffect, useRef, useState } from "react";
import { MenuDTO } from "../../../util/staticData/menu";
import styles from "./CascadeMenu.module.scss";
import { FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
interface ICascadeMenuProps {
  label: string;
  options: MenuDTO[];
  textLight?: boolean;
}

const CascadeMenu: FC<ICascadeMenuProps> = ({ label, options, textLight }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const refMenu = menuRef.current;

    if (refMenu) {
      refMenu.addEventListener("mouseenter", () => setIsOpen(true));
      refMenu.addEventListener("mouseleave", () => setIsOpen(false));
    }
    return () => {
      if (refMenu) {
        refMenu.removeEventListener("mouseenter", () => {});
        refMenu.removeEventListener("mouseleave", () => {});
      }
    };
  }, [menuRef]);

  return (
    <div className={styles.menuLinkWrapper} ref={menuRef}>
      <span className={`${styles.menuLink} ${textLight && styles.light}`}>
        {label} <FaCaretDown style={{ verticalAlign: "baseline" }} />
      </span>
      {isOpen && (
        <span className={styles.menuOptions}>
          {options.map((option) => {
            return (
              <Link
                to={option.href ?? ""}
                key={option.id}
                className={styles.optionLink}
              >
                {option.label}
              </Link>
            );
          })}
        </span>
      )}
    </div>
  );
};

export default CascadeMenu;
