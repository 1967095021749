import styles from "../PageCustomerSupport.module.scss";
import check from "../../../assets/images/charSupport/check.png";
import { FC, ReactNode } from "react";
import { Child } from "../../../util/models/ui.model";

export const ServicesGridStatic = () => {
  const Service: FC<{ right?: Boolean; children: ReactNode }> = ({
    right,
    children,
  }) => {
    return (
      <div className={styles.serviceWrapper}>
        <p className={`${styles.service} ${right && styles.right}`}>
          {!right && <img alt="check" src={check} />}
          <span>{children}</span>
          {right && <img alt="check" src={check} />}
        </p>
      </div>
    );
  };

  const I: FC<Child> = ({ children }) => {
    return <span className={styles.highlighted}>{children}</span>;
  };

  return (
    <>
      <div className={styles.servicesGrid}>
        <div className={styles.col}>
          <Service>
            <I>Multilingual</I> chat assistance to match the needs of{" "}
            <I>global businesses</I>.
          </Service>
          <Service>
            <I>Encrypted</I> and <I>private communication</I> modes to ensure
            customer privacy.
          </Service>
          <Service>
            <I>24/7 live chat assistance</I> to revert customer queries at any
            time of the day.
          </Service>
        </div>
        <div className={styles.col2}>
          <img
            src={require("../../../assets/images/charSupport/serviceChat.webp")}
            alt="Chat Service"
            className={styles.chatImage}
          />
        </div>
        <div className={styles.col}>
          <Service right>
            <I>Well-versed, courteous,</I> and committed customer support staff.
          </Service>
          <Service right>
            Basic <I>troubleshooting, verification,</I> and outbound{" "}
            <I>customer support.</I>
          </Service>
          <Service right>
            <I>Real-time</I> and cost-effective response.
          </Service>
        </div>
      </div>
      <p className="text-center text-secondary " style={{ fontWeight: 600 }}>
        Eventually, through live chat support services, every business
        enterprise gets a golden opportunity to strengthen its market share and
        potentially enhance its profit ratio.
      </p>
    </>
  );
};

export default ServicesGridStatic;
