import { FC, useEffect, useRef, useState } from "react";
import styles from "./ExploreText.module.scss";
import LoadingBar from "../LoadingBar/LoadingBar";
import {
  ASSETS_URL_IMG,
  VIDEO_DELAY,
} from "../../../../util/constants/generalConstants";
import { HomeData } from "../../../../util/staticData/homeData";
import ashvaIcon from "../../../../assets/images/ashvaIcon.svg";
import ankIcon from "../../../../assets/images/ankIcon.png";
const ExploreText: FC = () => {
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const hData = { ...HomeData };

  useEffect(() => {
    setTimeout(() => {
      setIsFirstLoaded(true);
      videoRef.current?.play();
    }, VIDEO_DELAY);

    return () => {};
  }, []);

  return (
    <div className={styles.explorationWrapper}>
      <div className={styles.videoWrapper}>
        <video
          autoPlay
          muted
          className={styles.video}
          src={hData.videoUrls.data}
        >
          <source src={hData.videoUrls.data} type="video/mp4"></source>
        </video>
      </div>

      <div
        className={`${styles.processingWrapper} ${
          isFirstLoaded && styles.show
        }`}
      >
        <div className={styles.videoWrapper}>
          <video
            ref={videoRef}
            muted
            className={styles.video}
            src={hData.videoUrls.solution}
          >
            <source src={hData.videoUrls.solution} type="video/mp4"></source>
          </video>
        </div>

        <LoadingBar />

        <div className={styles.productsButton}>
          {/* <div className={styles.note}>Explore our live Products</div> */}
          <div className={styles.buttonDiv}>
            <a
              href={"https://demo.ashva.app/"}
              target={"_blank"}
              className={styles.btnLink}
              rel="noreferrer"
            >
              <img
                src={ASSETS_URL_IMG + "checkout_ashva_racing.gif"}
                alt={"ashvaButton"}
                className={styles.buttonImage}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreText;
