import { FC, useState } from "react";
import { MenuDTO } from "../../../util/staticData/menu";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./AccordionMenu.module.scss";

interface IAccordionMenuProps {
  label: string;
  options: MenuDTO[];
  onClick: () => void;
}

const AccordionMenu: FC<IAccordionMenuProps> = ({
  label,
  options,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.menuLinkWrapper} onClick={() => setIsOpen(!isOpen)}>
      <span className={`${styles.menuLink} ${isOpen && styles.active}`}>
        <span>{label}</span>
        <span>
          {isOpen ? (
            <FaCaretDown style={{ verticalAlign: "baseline" }} />
          ) : (
            <FaCaretRight style={{ verticalAlign: "baseline" }} />
          )}
        </span>
      </span>
      {isOpen && (
        <span className={`${styles.menuOptions} ${isOpen && styles.open}`}>
          {options.map((option) => {
            return (
              <Link
                to={option.href ?? ""}
                key={option.id}
                className={styles.optionLink}
                onClick={onClick}
              >
                {option.label}
              </Link>
            );
          })}
        </span>
      )}
    </div>
  );
};

export default AccordionMenu;
