import { ASSETS_URL_IMG } from "../constants/generalConstants";

export const DataAnalyticsData = {
  coverImage: ASSETS_URL_IMG + "das_cover.webp",
  img: ASSETS_URL_IMG + "das_descriptionImage.webp",
  gif: "das_infographsAnim.gif",
  title: {
    part1: "Data &",
    part2: "Analytics",
  },
  descriptions: [
    "In this era of digitalization, data turns out to be a precious asset for every business. Industries like Information and Technology, Health, Hospitality, or Gaming heavily rely on data to sustain in the highly competitive market. Here comes data analytics which helps such business firms to swirl out raw data and equip refined information, which eventually helps them to gain better financial results as well as minimizes the risk factors drastically.     ",
    "However, companies often choose to outsource data analytics services rather than opting for in-house assistance. Creating an in-house department for data analytics has a fair amount of challenges as you need expert staff to handle the latest tools and technologies used in the process. Data privacy and data security can be at risk. A dedicated team of data scientists is needed to take care of the constant and lengthy process of data refining. Companies have to follow the method of combining and synchronizing unstructured data gathered from distinctive sources. All these components add to your cost and hamper productivity.     ",
    "We at Protocolzone Pvt Ltd, offer outsourcing services and solutions of data analytics that will synchronize your unrefined data using tactical, strategic, and systematic methods which will help you to get desired results. Based on your enterprise requirements, we offer a perfect combination of different analytics mechanisms like conventional analytics, making thorough use of data and various models to optimize your upcoming business decisions, predictive analysis which helps to foretell possible future results. Definitive analytics to make your corporation future-ready by reviewing past events.",
  ],
  // useCases: UseCases,
  techList: [
    {
      title: "Data Implementation Using",
      techs: [
        ASSETS_URL_IMG + "r.webp",
        ASSETS_URL_IMG + "stat.webp",
        ASSETS_URL_IMG + "sas.webp",
        ASSETS_URL_IMG + "h2o.webp",
      ],
    },
    {
      title: "Developed and Integrated platforms using",
      techs: [ASSETS_URL_IMG + "java.webp", ASSETS_URL_IMG + "python.webp"],
    },
    {
      title: "Visualizing using",
      techs: [ASSETS_URL_IMG + "react.webp", ASSETS_URL_IMG + "d3.webp"],
    },
    {
      title: "Developed and Integrated platforms using",
      techs: [
        ASSETS_URL_IMG + "aws.webp",
        ASSETS_URL_IMG + "gc.webp",
        ASSETS_URL_IMG + "linux.webp",
      ],
    },
  ],
  bi_visualization: {
    description:
      "Monitoring performance, spot trends, track goals, utilization of resources, and many other business needs can be micromanaged at a higher level with the use of data visualization. Our development team is capable of connecting with multiple data sources and producing comprehensive visualization. Reports for the different levels of the organization can be created with operational dashboards. Business users with a drill-down approach can arrive at relevant decisions to drive profitability and growth. Some of our offerings are:",
    offerings: [
      "Data Mining",
      "Benchmarking",
      "Business User Reporting",
      "Dashboarding",
      "Performance Management Tools",
    ],
  },
  data_modeling: {
    description:
      "Data models are built to identify future trends, patterns, behaviors, and threats. Our capable data science team collect and identify required data points, streamline and normalize data and mold it into the best suitable model to help business users achieve their goals. We built strategies and relevant solutions that automate business decisions and reduce overhead. Some of the services we offer are:",
    offerings: [
      "Ad-hoc Statistical Analysis",
      "Content, Image, and Video Analysis",
      "Predictive modeling",
      "Real-time time analytics and scoring",
      "Machine learning",
      "Data optimization",
      "Sentiment analysis",
    ],
  },
  serviceOffers: [
    "Healthcare Research",
    "Well-planned visualization solutions",
    "Domain-based analytics in diverse verticals",
    "Business and Financial research      ",
    "Sports Analytics",
    "Offshore data analytics support services",
    "Compelling data reporting, presentation, and data intelligence.",
  ],
};
