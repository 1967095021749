import { ROUTES } from "../constants/routerConstants";

export interface MenuDTO {
  id: number;
  label: string;
  href?: string;
  options?: MenuDTO[];
  isLink?: boolean;
}
// Options for  horse racing menu
export const HORSE_RACE_SERVICE_MENU: MenuDTO[] = [
  {
    label: "Optimization Service",
    id: 7,
    href: ROUTES.OPTIMIZATION_SERVICE,
  },
  { label: "24/7 Customer Service", id: 8, href: ROUTES.CUSTOMER_SERVICE },
  {
    label: "Data & Analytical Service",
    id: 9,
    href: ROUTES.DATA_ANALYTICS_SERVICE,
  },
  { label: "Event Trigger Service", id: 6, href: ROUTES.EVENT_TRIGGER_SERVICE },
];

export const PRODUCT_MENU: MenuDTO[] = [
  { label: "Ashva Platform", id: 5, href: ROUTES.FIXED_ODDS_SERVICE },

  { label: "Ank Platform", id: 11, href: ROUTES.ALGO_TRADING_PLATFORM },
];

export const MENU_LIST: MenuDTO[] = [
  { label: "Home", href: ROUTES.HOME, id: 0 },
  { label: "Products", options: PRODUCT_MENU, id: 10 },
  { label: "Services", id: 1, options: HORSE_RACE_SERVICE_MENU },
  { label: "Blogs", id: 12, isLink: true },
  { label: "Talk With Us", href: ROUTES.TALK_TO_US, id: 2 },
];
