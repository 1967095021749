import * as React from "react";
import styles from "./ETSUseCases.module.scss";

export interface IStaticUseCaseTableProps {}

export default function StaticUseCaseTable(props: IStaticUseCaseTableProps) {
  return (
    <table className={styles.useCaseTable}>
      <thead>
        <tr>
          <th>TRIGGERS</th>
          <th>RACE POOL TOTAL</th>
          <th>TIME</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Final Pool</td>
          <td>$ 298,458</td>
          <td></td>
        </tr>
        <tr>
          <td>Trigger 5</td>
          <td>$ 265,019</td>
          <td>Official Start Time</td>
        </tr>
        <tr>
          <td></td>
          <td>$ 217,077</td>
          <td></td>
        </tr>
        <tr>
          <td>Trigger 4</td>
          <td>$ 208,547</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 197,669</td>
          <td></td>
        </tr>
        <tr>
          <td>Trigger 3</td>
          <td>$190,568</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 184,434</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 176,293</td>
          <td></td>
        </tr>
        <tr>
          <td>Trigger 2</td>
          <td>$ 169,175</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$163,986</td>
          <td></td>
        </tr>

        <tr>
          <td>Trigger 1</td>
          <td>$ 156,772</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 151,973</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 145,107</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 135,257</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>$ 102,479</td>
          <td>Advertise Race Start Time</td>
        </tr>
        <tr>
          <td></td>
          <td>$ 98,756</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
}
