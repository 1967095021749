import styles from "./ankComingSoonAnimation.module.scss";

type Props = {};

const AnkComingSoonAnimation = (props: Props) => {
  return (
    <div className={styles.wrapper_nine}>
      <div>
        <h3 className={styles.rotate}>
          <span className={styles.ankColor}>A</span>
          <span className={styles.ankColor}>N</span>
          <span className={styles.ankColor}>K</span>
          <span className={styles.ankColor}>-</span>
          <span className={styles.ankColor}>P</span>
          <span className={styles.ankColor}>L</span>
          <span className={styles.ankColor}>A</span>
          <span className={styles.ankColor}>T</span>
          <span className={styles.ankColor}>F</span>
          <span className={styles.ankColor}>O</span>
          <span className={styles.ankColor}>R</span>
          <span className={styles.ankColor}>M</span>
          &nbsp;&nbsp;
          {/* <span>C</span>
          <span>O</span>
          <span>M</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
          &nbsp;&nbsp;
          <span>S</span>
          <span>O</span>
          <span>O</span>
          <span>N</span> */}
        </h3>
        <h4 className={styles.comingSoonText}>Demo Coming Soon</h4>
      </div>
    </div>
  );
};

export default AnkComingSoonAnimation;
