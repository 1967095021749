import "./App.scss";
import "./styles/layout.scss";
import "./styles/themes.scss";
import "./assets/provinceSprite/ip2location-image-sprite.scss";
import PzHeader from "./components/headers/PzHeader/PzHeader";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import { ROUTES, ROUTES_LIST } from "./util/constants/routerConstants";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Footer from "./pages/HomePage/Components/Footer/Footer";
import ReactGA from "react-ga";
import PageOurTeam from "./pages/OurTeam/PageOurTeam";
import PageFixedOddsManage from "./pages/PageFixedOddsManage/PageFixedOddsManage";
import PageEventTriggers from "./pages/PageEventTriggers/PageEventTriggers";
import PageWagerOptimization from "./pages/PageWagerOptimization/PageWagerOptimization";
import PageCustomerSupport from "./pages/PageCustomerSupport/PageCustomerSupport";
import PageDataAnalytics from "./pages/PageDataAnalytics/PageDataAnalytics";
import PzFixedHeader from "./components/headers/PzFixedHeader/PzFixedHeader";
import { FaArrowAltCircleUp } from "react-icons/fa";
import PageTalkToUs from "./pages/PageTalkToUs/PageTalkToUs";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import PageTermsOfUse from "./pages/PageTermsOfUse/PageTermsOfUse";
import PagePrivacyPolicy from "./pages/PagePrivacyPolicy/PagePrivacyPolicy";
import PageCareer from "./pages/PageCareer/PageCareer";
import PageAlgoTrading from "./pages/PageAlgoTrading/PageAlgoTrading";

const App: FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [cookieAccept, setCookieAccept] = useState(false);
  const [isScrollTopBtnVisible, setIsScrollTopBtnVisible] = useState(false);

  useEffect(() => {
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/621e2105a34c24564128e115/1ft2r1vjp";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode?.insertBefore(s1, s0);
    })();
    return () => {};
  }, []);

  // * Effect to setup scroll listener for scroll to top button
  useEffect(() => {
    const handleScroll = (e: any) => {
      if (headerRef.current) {
        let { top } = headerRef.current.getBoundingClientRect();
        top < -300
          ? setIsScrollTopBtnVisible(true)
          : setIsScrollTopBtnVisible(false);

        top < -40 ? setIsSticky(true) : setIsSticky(false);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // * Effect to initializa google analytics with cookie popup
  useEffect(() => {
    const Initializer = () => ReactGA.initialize("G-Y0V5WN1X3H");
    const cookie = getCookieConsentValue();

    if (cookie === "true") setCookieAccept(true);
    else setCookieAccept(false);

    Initializer();
    return () => {};
  }, []);

  const scrollTop = useCallback(
    () => window.scrollTo({ behavior: "auto", top: 0, left: 0 }),
    []
  );

  useEffect(() => scrollTop(), [location, scrollTop]);

  useEffect(() => {
    const { pathname } = location;
    const analyticsManage = () => {
      let pageView = pathname;
      if (!ROUTES_LIST.includes(pathname)) {
        pageView = `/404~${pathname}`;
      }
      ReactGA.set({ page: pageView });
      ReactGA.pageview(pageView);
      ReactGA.exception({
        description: "Something went wrong with google analytics",
        fatal: true,
      });
    };

    if (cookieAccept === true) analyticsManage();
  }, [location, cookieAccept]);

  // Page not found JSX
  const NotFound404 = () => {
    return (
      <div className="notFoundPage">
        <img src={require("./assets/images/404NotFound.png")} alt="" />
        <h1>404! Page Not Found!</h1>
      </div>
    );
  };

  // Fixed header JSX
  const FixedHead = () => {
    if (isSticky) return <PzFixedHeader />;
    return null;
  };

  // Scroll top button JSX
  const ScrollTopButton = (
    <div
      onClick={scrollTop}
      className={`wrapper ${isScrollTopBtnVisible && "show"}`}
    >
      <span className="topIcon">
        <FaArrowAltCircleUp size="1.5rem" />
      </span>
      <span className="text">Scroll Top </span>
    </div>
  );

  // Cookie Accept and Reject Methods
  const onAccept = (e: any) => setCookieAccept(true);
  const onReject = (e: any) => setCookieAccept(false);

  // UI
  return (
    <div className="pzDefault">
      {/* {isSticky ? <PzFixedHeader /> : <PzHeader />} */}
      <FixedHead />

      {ScrollTopButton}
      <div ref={headerRef}>
        <PzHeader />
      </div>
      {/* <TransitionGroup component="main">
        <CSSTransition key={location.key} classNames="fade" timeout={300}> */}
      <main className="back">
        <CookieConsent
          enableDeclineButton
          declineButtonText="Cancel"
          location="bottom"
          buttonText="Understand"
          onAccept={onAccept}
          onDecline={onReject}
          containerClasses="cookieContainer"
          buttonClasses="cookieBtns acceptBtn"
          declineButtonClasses="cookieBtns cancelBtn"
        >
          We use cookies to ensure that we give you the best experience on our
          website. If you continue without changing your settings, we’ll assume
          that you are willing to receive cookies.
        </CookieConsent>

        <Routes>
          <Route path={ROUTES.HOME} caseSensitive element={<HomePage />} />
          <Route
            path={ROUTES.FIXED_ODDS_SERVICE}
            caseSensitive
            element={<PageFixedOddsManage />}
          />
          <Route
            path={ROUTES.EVENT_TRIGGER_SERVICE}
            caseSensitive
            element={<PageEventTriggers />}
          />
          <Route
            path={ROUTES.OPTIMIZATION_SERVICE}
            caseSensitive
            element={<PageWagerOptimization />}
          />
          <Route
            path={ROUTES.CUSTOMER_SERVICE}
            caseSensitive
            element={<PageCustomerSupport />}
          />
          <Route
            path={ROUTES.DATA_ANALYTICS_SERVICE}
            caseSensitive
            element={<PageDataAnalytics />}
          />
          <Route
            path={ROUTES.TALK_TO_US}
            caseSensitive
            element={<PageTalkToUs />}
          />
          <Route
            path={ROUTES.PRIVACY_POLICY}
            caseSensitive
            element={<PagePrivacyPolicy />}
          />
          <Route
            path={ROUTES.TERMS_OF_USE}
            caseSensitive
            element={<PageTermsOfUse />}
          />
          <Route path={ROUTES.CAREER} caseSensitive element={<PageCareer />} />

          <Route
            path={ROUTES.OUR_TEAM}
            caseSensitive
            element={<PageOurTeam />}
          />

          <Route
            path={ROUTES.ALGO_TRADING_PLATFORM}
            caseSensitive
            element={<PageAlgoTrading />}
          />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
        <Footer />
      </main>
      {/* </CSSTransition>
      </TransitionGroup> */}
    </div>
  );
};

export default App;
