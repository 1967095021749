import styles from "./ETSUseCases.module.scss";
import StaticUseCaseTable from "./staticUseCaseTable";

export interface EtsUseCasesProps {}

export const EtsUseCases = (props: EtsUseCasesProps) => {
  return (
    <div className={styles.ETSUseCases}>
      <div>
        <h3 className={styles.title}>
          Following is one of the use-cases of using event trigger service in
          the horse racing domain.
        </h3>
      </div>
      <div className={styles.useCaseGrid}>
        <div>
          <StaticUseCaseTable />
        </div>
        <div className={styles.useCaseDescription}>
          <p>
            Generally, a traditional horse race betting bot or a high proportion
            of computer-assisted players/punters are dependent on the advertised
            race start time to automate this process where the pool total is not
            even near to the final pool total. This time can vary wildly from
            the official race start time, with delays caused by horses refusing
            to load, loose horses, scratchings, equipment issues, late jockey
            changes, etc. The advantage of using our service over the
            traditional method has been illustrated using a simple example
            below. If the puter uses the post time as a trigger, the pool is not
            even 40% of the final pool.
          </p>
          <p>
            Such delay impacts ROI when the bet calculation process is dependent
            on the pool total. Parameters that will be impacted are determining
            selection, staking, and risk calculation. Our Event Trigger Service
            helps to overcome most of the above problems with simple solutions
            in a cost-effective manner. We provide time-critical services to
            players wagering on pari-mutuel and exchange platforms to automate
            decision-making.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EtsUseCases;
