// export const RACE_TRIGGER_SERVICE = "/race-trigger-service";
// export const OUR_TEAM = "/our-team";
// export const CAREER = "/career";
// export const HOME = "/";

export const ROUTES = {
  HOME: "/",
  FIXED_ODDS_SERVICE: "/fixed-odds-service",
  EVENT_TRIGGER_SERVICE: "/event-trigger-service",
  OPTIMIZATION_SERVICE: "/optimization-service",
  CUSTOMER_SERVICE: "/customer-service",
  DATA_ANALYTICS_SERVICE: "/data-analytic-service",
  OUR_TEAM: "/our-team",
  TALK_TO_US: "/talk-to-us",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
  CAREER: "/career",
  ALGO_TRADING_PLATFORM: "/algo-trading_platform",
  BLOGS:"https://protocolzone.in/blogs/"
};

// List of defined routes to show in google analytics
export const ROUTES_LIST = Object.values(ROUTES);
