import { ASSETS_URL_IMG } from "../constants/generalConstants";

const Advantages = [
  {
    img: "adv1.webp",
    data: "You or your system might be good at predicting winning probabilities however we can help you to find optimal investment. Either it's betting, trading, or manufacturing.",
    anim: "animate__rotateInUpLeft",
  },
  {
    img: "adv2.webp",
    data: "In Horse racing, most systems that produce tips or bets are limited to simple bet types such as Win, Place, and Show however we focus more on complex exotic pools such as Exacta, Trifecta, Double and more that produce more returns.",
    anim: "animate__fadeInUp",
  },
  {
    img: "adv3.webp",
    data: "We expose APIs and do not require or store your sensitive information. Our APIs are easy to integrate and use the best infrastructure to reduce calculation and response time.",
    anim: "animate__rotateInUpRight",
  },
];

export const WagerOptimizationData = {
  coverImage: ASSETS_URL_IMG + "os_cover.webp",
  img: ASSETS_URL_IMG + "os_descriptionImage.webp",
  title: {
    part1: "Optimization",
    part2: "Service",
  },
  descriptions: [
    'There is a good saying that "Past Performance Does Not Guarantee Future Results" which is applicable to investing in horse racing, crypto-currencies, commodities, stock exchanges. The most important thing to make money for any such system is to have a correct staking plan and many “healthy” entry and exit strategies. Healthy specifically means a strategy that finds better value for your investment adjusting risk, reward, and your capital. As an example, your betting system may give you a 50% probability of winning the race on odds of 2/1, which may not be a good value for the investment.',
  ],
  advantages: Advantages,
  applications: [
    "Automatic Vehicle routing (VRP)",
    "Investments in crypto-currencies, commodities, stock exchanges.",
    "Finding the best values for selections in single outcome sports like football, cricket etc.",
    "Finding the best values for selections in multiple outcome sports like racing.",
    "Finding the best time for investment.",
    "Fund allocation in multiple industries",
  ],
};
