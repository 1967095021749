import { FC, ReactNode } from "react";
import { DataAnalyticsData } from "../../util/staticData/dataAnalyticsData";
import TechList from "./components/TechList/TechList";
import styles from "./PageDataAnalytics.module.scss";
import check from "../../assets/images/charSupport/check.png";
import { Helmet } from "react-helmet";
import { Child } from "../../util/models/ui.model";

export const PageDataAnalytics = () => {
  const daData = { ...DataAnalyticsData };

  const Governance = (
    <div>
      <div className={"titleWrapper"}>
        <span>Data Management &nbsp;</span>
        <span className={"right"}>And Governance</span>
      </div>
      <div className={`my2`}>
        Our data management process ensures data is reliable and accessible when
        it’s needed and to those who make a decision on it. Data is a costly and
        the most important asset of the organization, it needs to be protected
        and accessible with proper access control. Our team has performed many
        SSO integration, data planning, structuring, cataloging, and monitoring
        while ensuring adhering to regulation, policies, and authorization.
      </div>
    </div>
  );

  const DataModeling = () => {
    return (
      <div>
        <div className={"titleWrapper"}>
          <span>Data Modeling &nbsp;</span>
          <span className={"right"}>& Forecasting</span>
        </div>
        <div className="my2">
          <p>{daData.data_modeling.description}</p>
          <div className={styles.offeringWrapper}>
            {daData.data_modeling.offerings.map((offer, i) => {
              return (
                <div className={styles.offer} key={i}>
                  {offer}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const BI = () => {
    return (
      <div>
        <div className={"titleWrapper"}>
          <span>BI AND &nbsp;</span>
          <span className={"right"}>Data Visualization</span>
        </div>
        <div className="my2">
          <p>{daData.bi_visualization.description}</p>
          <div className={styles.offeringWrapper}>
            {daData.bi_visualization.offerings.map((offer, i) => {
              return (
                <div className={styles.offer} key={i}>
                  {offer}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const Li: FC<Child> = ({ children }) => {
    return (
      <div className={styles.listContainer}>
        <img src={check} alt="check" />
        <span>{children}</span>
      </div>
    );
  };

  const Offers = () => {
    return (
      <div className={styles.offersContainer}>
        <div className={"titleWrapper"}>
          <span className={"left"}>We Have Offered &nbsp;</span>
          <span>Analytics Services Including</span>
        </div>
        {daData.serviceOffers.map((offer, index) => {
          return <Li key={index}>{offer}</Li>;
        })}
      </div>
    );
  };

  return (
    <div className={styles.PageDataAnalytics}>
      <Helmet>
        <title>Data Analytics Service - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/data-analytic-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className={"landingImage"}>
        <img src={daData.coverImage} alt="Landing" />
        <h1>Data & Analytical Service</h1>
      </div>
      <div className={`headDescription ${styles.daRow}`}>
        <div className={`col1 d-flex ${styles.daCol1}`}>
          <div className={`descImage ${styles.descImg}`}>
            <img
              src={`https://storage.googleapis.com/pzone-site-images/das_Inforgraphs.gif`}
              alt="Description"
            />
          </div>
        </div>
        <div className={`col2 ${styles.daCol2}`}>
          <div className={"descriptionWrapper"}>
            <div className={"titleWrapper"}>
              <span>{daData.title.part1} &nbsp;</span>
              <span className={"right"}>{daData.title.part2}</span>
            </div>
            <div className={"description"}>
              {daData.descriptions.map((desc, index) => {
                return <p key={index}>{desc}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <Offers />
        <TechList />
        {Governance}
        <BI />
        <DataModeling />
      </div>
    </div>
  );
};

export default PageDataAnalytics;
