import { ASSETS_URL_IMG } from "../constants/generalConstants";

export const EventTriggersUsage = [];

const TriggerUsage = [
  {
    image: "stockMarket.webp",
    use: "Stock Market Traders",
  },
  {
    image: "crypto.webp",
    use: "Crypto-Currency Traders",
  },
  {
    image: "bookmakers.webp",
    use: "Sports or Horse Betting Bookmakers ",
  },
  {
    image: "player.webp",
    use: "Sports or Horse Racing Players",
  },
];

export const EventTriggersData = {
  img: ASSETS_URL_IMG + "ets_descriptionImage.webp",
  coverImage: ASSETS_URL_IMG + "ets_cover.webp",

  title: {
    part1: "Event",
    part2: "Triggers",
  },
  descriptions: [
    "We have a 24x7 highly trained professional data monitoring and analysis team to monitor live data endpoints based on your requirements. We have a technical development team to implement solutions suited to your business. Our data science and research team can help to find the best data points for your business needs. Event trigger service will ensure that your system will be notified with customized API when the data point is matched with live real-time events.",
  ],
  triggerUsage: TriggerUsage,
};
