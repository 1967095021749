import { useEffect, useRef } from "react";
import { VIDEO_DELAY } from "../../../../util/constants/generalConstants";
import { HomeData } from "../../../../util/staticData/homeData";
import styles from "./LoadingBar.module.scss";

export const LoadingBar = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setTimeout(() => {
      videoRef.current?.play();
    }, VIDEO_DELAY);
  }, []);

  return (
    <div className={styles.loaderWrapper}>
      <video
        ref={videoRef}
        muted
        className={styles.video}
        src={HomeData.videoUrls.loading}
      >
        <source src={HomeData.videoUrls.loading} type="video/mp4"></source>
      </video>
    </div>

    
  );
};

export default LoadingBar;
