import { DataAnalyticsData } from "../../../../util/staticData/dataAnalyticsData";
import styles from "./TechList.module.scss";
import check from "../../../../assets/images/charSupport/check.png";

export const TechList = () => {
  const techList = [...DataAnalyticsData.techList];

  return (
    <div className={styles.TechList}>
      <div className={"titleWrapper"}>
        <span>We Have &nbsp;</span>
        <span className={"right"}>Already Performed</span>
      </div>
      <div className={styles.contentWrapper}>
        {techList.map((category, index) => {
          return (
            <div className={styles.categoryWrapper} key={index}>
              <div className={styles.title}>
                <img src={check} alt="check" />
                <span>{category.title}</span>
              </div>
              <div className={styles.gridWrapper}>
                <div className={styles.techsGrid}>
                  {category.techs.map((techUrl, i) => {
                    return (
                      <div className={styles.techCard} key={i}>
                        <img src={techUrl} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TechList;
