import { ChangeEvent } from "react";
import styles from "./formInput.module.scss";

export interface FormInputProps {
  title?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  inputClass?: string;
  label?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  errors?: string | null;
  errorKey?: string;
  setErrors?: (key: string, str: string | null) => void;
  autoFocus?: boolean;
}

export const FormInput = (props: FormInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(e);
  };

  return (
    <div className={`${styles.formInput} ${props.className} `}>
      <div className={styles.floatingLabelWrapper}>
        <input
          className={`${styles.inpBox} ${props.errors && styles.inpError}`}
          type={props.type ?? "text"}
          value={props.value ?? ""}
          name={props.name}
          placeholder={props.placeholder ?? props.label}
          onChange={handleChange}
          autoFocus={props.autoFocus}
        />
        <label className={styles.inpLabel}>{props.label}</label>
        <span className={styles.error}>{props.errors}</span>
      </div>
    </div>
  );
};

export default FormInput;
