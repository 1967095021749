import styles from "./PzHeader.module.scss";
import PzLogoDark from "../../../assets/images/pzlogoDark.svg";
import Menu from "../../Menu/Menu";
import { Link } from "react-router-dom";
import { VirtualNumbers } from "../../../util/staticData/virtualNumbers";
import VirtualCallUs from "./VirtualCallUs/VirtualCallUs";
import { ROUTES } from "../../../util/constants/routerConstants";

const PzHeader = () => {
  const NumberOptions = VirtualNumbers.map((num) => {
    return (
      <div key={num.id} className={styles.numbers}>
        <span
          className={`ip2location-flag-32 flag-${
            num.countryCode?.toLowerCase() ?? "au"
          }`}
        ></span>
        <a href={`tel:${num.wholeNumber}`}>
          {num.phoneCode} {num.firstPart} {num.lastPart}{" "}
        </a>
      </div>
    );
  });

  return (
    <div>
      <div className={styles.numbersWrapper}>{NumberOptions}</div>
      <div className={styles.headerWrapper}>
        <div className={`${styles.header} `}>
          <Link to={ROUTES.HOME}>
            <div className={`${styles.logoWrapper} `}>
              <img className={styles.image} src={PzLogoDark} alt="LOGO" />
            </div>
          </Link>
          <span className="d-flex align-items-center">
            <Menu />
            <VirtualCallUs />
          </span>
        </div>
      </div>
    </div>
  );
};

export default PzHeader;
