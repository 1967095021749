export interface TeamMemberDTO {
  id: number;
  name: string;
  designation: string;
  imgUrl: string;
}

export const TAB_HEAD = [
  { label: "All", field: "all" },
  { label: "Management", field: "management" },
  { label: "Development", field: "development" },
  { label: "Customer Support", field: "customer_support" },
  { label: "Data Entry Operators", field: "operators" },
];

export const TEAM_DATA: { [type: string]: TeamMemberDTO[] } = {
  management: [
    {
      id: 1,
      name: "Kartik Modi",
      designation: "Director",
      imgUrl: "kartik",
    },
    {
      id: 2,
      name: "Komal Modi",
      designation: "Director",
      imgUrl: "komal",
    },
  ],
  development: [],
  customer_support: [],
  operators: []
};
