import styles from "./PagePrivacyPolicy.module.scss";
import { Helmet } from "react-helmet";

export const PagePrivacyPolicy = () => {
  return (
    <div className={styles.PagePrivacyPolicy}>
      <Helmet>
        <title>Privacy Policy - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/privacy-policy"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <h1 className="text-center">Privacy Policy</h1>
      <div className="container">
        <section>
          <h3>The effective date of this Policy is May 17, 2018</h3>
          <p>
            Your privacy is important to us. Protocolzone and its subsidiaries
            or affiliated companies hereinafter referred as ‘Protocolzone’ is
            committed to respect your privacy while using our website. This
            policy applies to information collected by us on our website.
          </p>
        </section>
        <section>
          <h3>Definitions</h3>
          <p>
            “Personally Identifiable Information”(or ‘PII”) means any
            information that relates to a natural person, which, either directly
            or indirectly, in combination with other information available or
            likely to be available with a corporate body, is capable of
            identifying such person.
          </p>
        </section>
        <section>
          <h3>The information we collect:</h3>
          <p>
            We do not collect Personally Identifiable Information about you,
            unless you choose to fill out your information on this website
            through specific provided forms. In general, you may browse our
            website without providing any Personal Identifiable Information
            about yourself. We do automatically collect certain information when
            you visit our website such as the type of operating system, the type
            of browser you are using, your IP address in order to provide better
            usability and personalized experience.
          </p>
          <p>
            Protocolzone shall only process the received information pursuant to
            pursue our legitimate business interests for establishing
            communication with prospective customers and personnel with general/
            business inquiries.
          </p>
        </section>
        <section>
          <h3>How we normally use information about you</h3>
          <p>
            We use certain information to analyze site usage for us to improve
            our website.
          </p>
          <p>
            We use Personally Identifiable Information you provide solely to
            respond to your inquiry or to process an application form you have
            completed on our website or to respond to any legal process or as
            required by law in response to any court order, subpoena or a law
            enforcement agency or to take action against any illegal activity
            and to address any potential threat to the physical safety of a
            person.
          </p>
        </section>
        <section>
          <h3>Information sharing</h3>
          <p>
            We may share your Personal Identifiable Information within
            Protocolzone, Protocolzone group or with any of our subsidiaries and
            very occasionally our business partners, service vendors or
            authorized third-party agents located in any part of the world for
            the purposes of data processing, enrichment, storage, or to provide
            a requested service or transaction, after ensuring that such
            entities are contractually bound by data privacy obligations.
          </p>
        </section>
        <section>
          <h3>Your rights</h3>
          <p>
            You may have certain rights relating to your Personally Identifiable
            Information provided for under applicable law. These are the right
            to:
          </p>
          <ul>
            <li>
              Request access to your Personally Identifiable Information and the
              processing activities on the same.
            </li>
            <li>
              Request that your Personally Identifiable Information is rectified
              if it is inaccurate or incomplete.
            </li>
            <li>
              Request erasure of your Personally Identifiable Information in
              certain circumstances.
            </li>
            <li>
              Request restriction of the processing of your Personally
              Identifiable Information in certain circumstances.
            </li>
            <li>
              Object to the processing of your Personally Identifiable
              Information in certain circumstances.
            </li>
            <li>
              Receive your Personally Identifiable Information provided to us as
              a controller in a structured, commonly used and machine-readable
              format in certain circumstances.
            </li>
            <li>Lodge a complaint with a supervisory authority.</li>
            <li>
              Withdraw your consent provided at any time by contacting us.
            </li>
            <li>
              To exercise the rights outlined above in respect of your
              Personally Identifiable Information please contact the details
              available in the contact section below. We will endeavor to
              respond to you within appropriate as required by the law.
            </li>
          </ul>
        </section>
        <section>
          <h3>Authenticity</h3>
          <p>
            Protocolzone shall not be responsible for the authenticity of the
            information and it is your duty to ensure that the information
            provided is authentic.
          </p>
        </section>
        <section>
          <h3>Cookies and other technologies</h3>
          <p>
            Our website uses cookies. A cookie is a text-only string of
            information that a website transfers to the cookie file of the
            browser on your computer’s hard disk so that website can remember
            who you are. Please read our Cookie Policy carefully for more
            details about the information we collect when you use this site.
          </p>
        </section>
        <section>
          <h3>Data storage and retention</h3>
          <p>
            Your PII is stored in databases located at India and on the servers
            of the cloud-based database management services that ProtocolZone
            engages.
          </p>
          <p>
            ProtocolZone shall retain your PII Personal Information pursuant to
            the business purposes and in line with our retention policies.
          </p>
          <p>
            PII received from prospective customers shall be retained for the
            duration of the prospective customer’s business relationship with
            the ProtocolZone.
          </p>
        </section>
        <section>
          <h3>Links to third party websites</h3>
          <p>
            Our website may contain links to other sites including social media
            sites, whose information practices may be different from ours. You
            should read such third party’s websites privacy notices and
            ProtocolZone does not control those sites or their privacy
            practices. We do not endorse or make any representations about
            third-party websites.
          </p>
        </section>
        <section>
          <h3>Our commitment to security</h3>
          <p>
            Protocolzone utilizes appropriate physical, technical and
            administrative procedures to safeguard the information we collect.
            Protocolzone takes reasonable steps to ensure that all dealings in
            personally identifiable information is processed fairly & lawfully
            after receiving consent of the information provider. Protocolzone
            may retain your personally identifiable information as long as there
            is a business requirement, or if otherwise required under applicable
            laws.
          </p>
        </section>
        <section>
          <h3>Changes to this Policy</h3>
          <p>
            Please note that this policy may change from time to time. Please
            review the policy periodically for changes.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PagePrivacyPolicy;
