import styles from "./Footer.module.scss";
import {
  FaClock,
  FaFacebookF,
  FaLink,
  FaLinkedin,
  FaLocationArrow,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { GrMap } from "react-icons/gr";
import { MdContacts } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { ROUTES } from "../../../../util/constants/routerConstants";
import { Link } from "react-router-dom";
import {
  NumbersDTO,
  VirtualNumbers,
} from "../../../../util/staticData/virtualNumbers";
import { ASSETS_URL_IMG } from "../../../../util/constants/generalConstants";
import { useState } from "react";
import { format } from "date-fns";

const Footer = () => {
  const NumberLink = (numObj: NumbersDTO) => {
    return (
      <div className={styles.linkWrapper} key={numObj.id}>
        <FaPhone size="0.75rem" />{" "}
        <a href={`tel:${numObj.wholeNumber}`} className={styles.contactLink}>
          {numObj.phoneCode} {numObj.firstPart} {numObj.lastPart}{" "}
        </a>
        <span
          className={`ip2location-flag-32 flag-${numObj.countryCode?.toLowerCase()}`}
        ></span>
      </div>
    );
  };

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerContent}>
        <div className={styles.logoSocialWrapper}>
          <div className={styles.logo}>
            <img src={ASSETS_URL_IMG + "footerlogo.svg"} alt="Pz Logo" />
          </div>
          <div className={styles.socials}>
            <a
              href="https://in.linkedin.com/company/protocol-zone"
              target="_blank"
              className={styles.linkedIn}
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.facebook.com/ProtocolzonePvtLimited/"
              target="_blank"
              rel="noreferrer"
              className={styles.fb}
            >
              <FaFacebookF />
            </a>
            <a
              href="https://twitter.com/protocolzone"
              target="_blank"
              rel="noreferrer"
              className={styles.twt}
            >
              <FaTwitter />
            </a>
          </div>
        </div>
        <div className={styles.infoGrid}>
          <div className={styles.quickLinks}>
            <h4 className={styles.gridHeader}>
              <FaLink /> <span className="px-2"> Quick Links</span>
            </h4>
            <div className={styles.linksWrapper}>
              <div>
                <Link className={styles.quickLink} to={"/"}>
                  Home
                </Link>
              </div>
              <div>
                {" "}
                <Link className={styles.quickLink} to={ROUTES.PRIVACY_POLICY}>
                  Privacy Policy
                </Link>
              </div>
              <div>
                {" "}
                <Link className={styles.quickLink} to={ROUTES.TERMS_OF_USE}>
                  Terms of Use
                </Link>
              </div>
              <div>
                <a className={styles.quickLink} href={"https://protocolzone.in/"}>
                  Career
                </a>
              </div>
              <div>
                {" "}
                <Link className={styles.quickLink} to={ROUTES.TALK_TO_US}>
                  Get In Touch
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.hours}>
            <h4 className={styles.gridHeader}>
              <FaClock /> <span className="px-2">Hours</span>
            </h4>
            <div>Monday - Friday</div>
            <div>9:00 - 6:00 PM</div>
            <div className="pt-4">
              <h4 className={styles.gridHeader}>
                <MdContacts /> <span className="px-2">Contact Us</span>
              </h4>

              {VirtualNumbers.map((n) => {
                return NumberLink(n);
              })}
              <div className={styles.linkWrapper}>
                <FiMail size="0.9rem" />{" "}
                <a
                  href="mailto:info@protocolzone.com"
                  className={styles.contactLink}
                >
                  info@protocolzone.com{" "}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.contact}>
            <div className="">
              <h4 className={styles.gridHeader}>
                {" "}
                <FaLocationArrow /> &nbsp; Development Office
              </h4>
              <p>
                B/1105, Titanium Heights <br />
                Near Vodafone House <br />
                Corporate Road <br />
                Makarba, Ahmedabad - 380015
              </p>
              <span>
                <a
                  target="_blank"
                  href="https://g.page/pzoneltd?share"
                  className={styles.locateBtn}
                  rel="noreferrer"
                >
                  <GrMap /> <span>Locate Us</span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.footerCopyright} `}>
        <div className="container">
          Copyright © {format(new Date(), "yyyy")} Protocolzone Private Limited.
        </div>
      </div>
    </div>
  );
};

export default Footer;
