import styles from "./PageTermsOfUse.module.scss";

export interface PageTermsOfUseProps {}

export const PageTermsOfUse = () => {
  return (
    <div className={styles.PageTermsOfUse}>
      <h1 className="text-center">Terms Of Use</h1>
      <div className="container">
        <p>
          The User shall be deemed to have accepted and agreed to the following
          terms and conditions upon the Users usage of the website
          www.protocolzone.com
        </p>
        <ol>
          <li>
            The term Protocolzone shall mean Protocolzone and the term User
            shall mean the user who is browsing the website www.protocolzone.com
            (Site)
          </li>
          <li>
            By using the Site, User agrees to follow and be bound by the
            following terms and conditions and these terms and conditions may be
            revised or modified at any time by Protocolzone without any notice
            to User.
          </li>
          <li>
            www.protocolzone.com is owned by Protocolzone. All content present
            on this Site is the exclusive property of Protocolzone including all
            the software, text, images, graphics, video and audio used on this
            Site.
          </li>
          <li>
            Protocolzone name and logo are trademarks which belong to and are
            owned by Protocolzone. These trademarks may not be used in any
            manner without prior written consent from Protocolzone.
          </li>
          <li>
            No material from this site may be copied, modified, reproduced,
            republished, uploaded, transmitted, posted or distributed in any
            form without prior written permission from Protocolzone. All rights
            not expressly granted herein are reserved.
          </li>
          <li>
            Unauthorized use of the materials appearing on this Site may violate
            copyright, trademark and other applicable laws, and could result in
            criminal or civil penalties. Protocolzone may monitor access to the
            Site.
          </li>
          <li>
            Protocolzone may terminate User’s access to the Site at any time for
            any reason. The provisions regarding disclaimer of warranty,
            accuracy of information, and indemnification shall survive such
            termination.
          </li>
          <li>
            Protocolzone makes no warranties, express or implied, including,
            without limitation, those of merchantability and fitness for a
            particular purpose, with respect to any information, data,
            statements or products made available on the Site.
          </li>
          <li>
            The Site, and all content, materials, information, software,
            products and services provided on the Site, are provided on an “as
            is” and “as available” basis.
          </li>
          <li>
            Protocolzone shall have no responsibility for any damage to User’s
            computer system or loss of data that results from the download of
            any content, materials, information from the Site.
          </li>
          <li>
            Protocolzone may unilaterally change or discontinue any aspect of
            the Site at any time, including, its content or features.
          </li>
          <li>
            Protocolzone will not be liable for damages of any kind, including
            without limitation, direct, incidental or consequential damages
            (including, but not limited to, damages for lost profits, business
            interruption and loss of programs or information) arising out of the
            use of or inability to use the Site, or any information provided on
            the Site, or for any other reason whatsoever.
          </li>
          <li>
            User agrees to indemnify, defend and hold Protocolzone harmless from
            and against all losses, expenses, damages and costs, including
            reasonable attorneys’ fees, arising out of or relating to any misuse
            by the User of the content and services provided on the Site.
          </li>
          <li>
            The information contained in the Site has been obtained from sources
            believed to be reliable. Protocolzone disclaims all warranties as to
            the accuracy, completeness or adequacy of such information.
          </li>
          <li>
            The Site provides links to web sites and access to content, products
            and services from third parties, including users, advertisers,
            affiliates and sponsors of the Site. User agrees that Protocolzone
            is not responsible for the availability of, and content provided on,
            third party web sites. The User is requested to peruse the policies
            posted by other web sites regarding privacy and other topics before
            use. Protocolzone is not responsible for third party content
            accessible through the Site, including opinions, advice, statements
            and advertisements, and User shall bear all risks associated with
            the use of such content. Protocolzone is not responsible for any
            loss or damage of any sort User may incur from dealing with any
            third party.
          </li>
          <li>
            Protocolzone makes no warranty that: (a) the Site will meet your
            requirements; (b) the Site will be available on an uninterrupted,
            timely, secure, or error-free basis; (c) the results that may be
            obtained from the use of the Site or any services offered through
            the Site will be accurate or reliable.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PageTermsOfUse;
