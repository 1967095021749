import styles from "./PageCareer.module.scss";
import { Helmet } from "react-helmet";

export const PageCareer = () => {
  return (
    <div className={styles.PageCareer}>
      <Helmet>
        <title>Career - Protocol Zone </title>
        <link rel="canonical" href="https://www.protocolzone.com/career" />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <iframe
        title={"Make your career with Protocol Zone."}
        className={styles.frame}
        src="https://career.protocolzone.com/careers/"
      ></iframe>
    </div>
  );
};

export default PageCareer;
