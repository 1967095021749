import React, { FC } from "react";
import { ASSETS_URL_VIDEO } from "../../../../util/constants/generalConstants";
import styles from "./PzoneVideo.module.scss";

const PzoneVideo: FC = () => {
  return (
    <div className={styles.videoContainer}>
      <video
        autoPlay
        muted
        loop
        className={styles.video}
        src={ASSETS_URL_VIDEO+"pzoneHome.mp4"}
      >
        <source
          src={ASSETS_URL_VIDEO+"pzoneHome.mp4"}
          type="video/mp4"
        ></source>
      </video>
    </div>
  );
};

export default PzoneVideo;
