import { Link } from "react-router-dom";
import { WhatWeDoSectionsDTO } from "../../../../util/staticData/whatWeDoData";
import styles from "./WhatWeDoSections.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { MdStars } from "react-icons/md";
export interface IDynamicWhatWeDoProps {
  section: WhatWeDoSectionsDTO;
}

export default function DynamicWhatWeDo({ section }: IDynamicWhatWeDoProps) {
  return (
    <div className={`${styles.pzRow} ${styles[section.imgPos]}`}>
      <div className={`${styles.pzCol}`}>
        <AnimationOnScroll
          animateIn={
            section.imgPos === "left" ? section.anim.img : section.anim.body
          }
        >
          <img src={section.img} alt={"Service"} className={styles.defImage} />
        </AnimationOnScroll>
      </div>
      <div className={`${styles.pzCol}`}>
        <AnimationOnScroll
          animateIn={
            section.imgPos === "left" ? section.anim.img : section.anim.body
          }
        >
          <div className={styles.colBody}>
            <h1 className={styles.title}>{section.title}</h1>
            {section.body.map((str, index) => {
              return (
                <p key={index} dangerouslySetInnerHTML={{ __html: str }}></p>
              );
            })}

            {section.note && (
              <div className={styles.note}>
                <span>
                  <MdStars />
                </span>
                <span>{section.note}</span>
              </div>
            )}

            <span className={`${styles.linkWrapper}`}>
              <span className={`${styles.link} ${styles[section.imgPos]}`}>
                <Link to={section.link}>Know More</Link>
              </span>
            </span>
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
}
