import { ASSETS_URL_IMG } from "../constants/generalConstants";
import { ROUTES } from "../constants/routerConstants";
import { CustomerSupportData } from "./customerSupportData";
import { DataAnalyticsData } from "./dataAnalyticsData";
import { EventTriggersData } from "./eventTriggersData";
import { WagerOptimizationData } from "./wagerOptimizationData";

export interface WhatWeDoSectionsDTO {
  id: number;
  img: string;
  title: string;
  body: string[]; // list of paragraphs
  anim: {
    img: string;
    body: string;
  };
  imgPos: "right" | "left";
  //   textPos: "right" | "left";
  link: string;
  note?: string;
}

export const WhatWeDoSections: WhatWeDoSectionsDTO[] = [
  {
    id: 1,
    img: ASSETS_URL_IMG + "fos_descriptionImage.webp",
    title: "Fixed Odds Service",
    body: [
      "With our years of domain experience and technology, we have built a feature-rich <b class='highlight'> fixed odds platform </b> for horse racing. Our fixed-odds platform is  <b class='highlight'> cloud-based </b> and supports most of the regions across the globe. It can connect with any data providers such as <b class='highlight'> Amtote, Betfair, UK Tote,</b> or any custom data connectors. The platform is capable of handling both<b class='highlight'> Tote and Fixed odds</b> bets from the customers. The platform has its own <b class='highlight'> Risk management</b> that automates <b class='highlight'> layoff </b> for high-risk selections and reduces exposure. The platform is capable of integrating <b class='highlight'> new bet types </b> to cater to evolving business needs. It has <b class='highlight'> open APIs </b> to integrate with any <b class='highlight'> CRM, CMS, Payment gateway, and wallet management.</b> We provide a <b class='highlight'> white-labeling service </b> to provide a <b class='highlight'> responsive, multilingual, and customizable user interface.</b>",
    ],
    note: "Do you want to integrate horse racing into your existing or new product without any management overhead? We have the right solution for you.",
    anim: {
      img: "animate__zoomIn",
      body: "animate__zoomIn",
    },
    imgPos: "left",
    link: ROUTES.FIXED_ODDS_SERVICE,
  },
  {
    id: 2,
    img: EventTriggersData.img,
    title: "Event Trigger Service",
    body: [
      "We have a <b class='highlight'> 24x7 </b> highly trained professional <b class='highlight'> data monitoring </b> and analysis team to monitor <b class='highlight'> live data </b> endpoints based on your requirements. We have a technical development team to implement solutions suited to your business. Our data science and research team can help to find the best data points for your business needs. Event trigger service will ensure that your system will be notified with <b class='highlight'> customized API </b> when the data point is matched with <b class='highlight'> live real-time events.</b> ",
    ],
    note: "Can any real-time event trigger help your business? Talk with us we can get that implemented for you.",
    anim: {
      img: "animate__zoomIn",
      body: "animate__zoomIn",
    },
    imgPos: "right",
    link: ROUTES.EVENT_TRIGGER_SERVICE,
  },
  {
    id: 3,
    img: WagerOptimizationData.img,
    title: "Optimization Service",
    body: [
      "There is a good saying that <b class='highlight'>\"Past Performance Does Not Guarantee Future Results\" </b> which is applicable to investing in <b class='highlight'> horse racing, crypto-currencies, commodities, stock exchanges.</b> The most important thing to <b class='highlight'> make money </b> for any such system is to have a correct <b class='highlight'> staking plan </b> and many “healthy” <b class='highlight'> entry and exit strategies. </b> Healthy specifically means a strategy that finds <b class='highlight'> better value </b> for your investment adjusting <b class='highlight'> risk, reward,</b> and your <b class='highlight'> capital. </b> As an example, your betting system may give you a 50% probability of winning the race on odds of 2/1, which may not be a good value for the investment. ",
    ],
    note: "Are you looking for the optimal return from your investment? I am sure we will be able to help you there. ",
    anim: {
      img: "animate__zoomIn",
      body: "animate__zoomIn",
    },
    imgPos: "left",
    link: ROUTES.OPTIMIZATION_SERVICE,
  },
  {
    id: 4,
    img: CustomerSupportData.img,
    title: "24/7 Customer Support",
    body: [
      "Customers are often faced with the dilemma of where to go and whom to contact when they face issues. This is where strong <b class='highlight'> customer support </b> comes into play. Customer support is crucial for any organization to survive in this <b class='highlight'> competitive market.</b> Below-par customer service can negatively impact your <b class='highlight'> brand </b>, even when your <b class='highlight'> product </b> is of top-notch quality. It is crucial to deliver <b class='highlight'> qualitative </b> after-sales services to not only maintain <b class='highlight'> steady customer relationships </b> but build their <b class='highlight'> confidence in your business.</b> ",
    ],
    anim: {
      img: "animate__zoomIn",
      body: "animate__zoomIn",
    },
    imgPos: "right",
    link: ROUTES.CUSTOMER_SERVICE,
  },
  {
    id: 5,
    img: DataAnalyticsData.img,
    title: "Data & Analytical Service",
    body: [
      "Today’s <b class='highlight'> digital transformation </b> for every business depends on data and analytics. Past and present information drives business and takes <b class='highlight'> futuristic decisions.</b> Today every business in a simple or complex way uses available information and historical domain data to <b class='highlight'> forecast sales, increase clientele, reduce overhead, optimize expenses, and automate decision-making.</b> Protocolzone can be your <b class='highlight'> technical partner </b> in order to drive your business to the new edge. Our engineers constantly strive to find <b class='highlight'> simple, reliable, valuable</b> and <b class='highlight'> effective </b> solutions using <b class='highlight'> open-source technologies </b> and <b class='highlight'> tools. </b> ",
    ],
    note: "Do you want to know how your own historical data can help you to make better decisions? Give our team a chance and you won't regret it.",
    anim: {
      img: "animate__zoomIn",
      body: "animate__zoomIn",
    },
    imgPos: "left",
    link: ROUTES.DATA_ANALYTICS_SERVICE,
  },
];
