import { useState } from "react";
import { SERVICES } from "../../util/staticData/staticObjects";
import FormInput from "./formInput/formInput";
import FormSelect from "./formSelect/formSelect";
import styles from "./PageTalkToUs.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { firestore } from "../../util/configs/firebase.config";
import { addDoc, collection } from "firebase/firestore";
import { Helmet } from "react-helmet";
import { Captcha } from "primereact/captcha";
import numCodes from "../../assets/countryNumberCodes/countryNumberCodes.json";

import {
  CAPTCHA_SITEKEY,
  HOST_EMAIL,
} from "../../util/constants/generalConstants";
import CountrySelect, { CountryType } from "./formSelect/countrySelect";

interface EnquiryDTO {
  preference: "e-mail" | "phone";
  name: string;
  country: CountryType;
  [key: string]: any;
}

export const PageTalkToUs = () => {
  const [enquiry, setEnquiry] = useState<EnquiryDTO>({
    preference: "e-mail",
    name: "",
    phone: "",
    country: {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
  });
  const [formResponse, setFormResponse] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [errors, setErrors] = useState<{ [type: string]: string | null }>({});
  const [token, setToken] = useState<string | null>(null);

  const updateErrors = (key: string, message: string | null) => {
    let _errors = { ...errors };
    if (message) {
      _errors[key] = message;
    } else {
      delete _errors[key];
    }
    setErrors(_errors);
  };

  const handleChange = (name: string, value: any) => {
    if (isClear) setIsClear(false);
    setErrors({});
    let _enquiry = { ...enquiry };
    _enquiry[name] = value;
    setEnquiry(_enquiry);
  };

  const isValidEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateForm = () => {
    if (errors["email"]) return false;
    if (enquiry.preference === "e-mail" && !enquiry["email"]) {
      setErrors({ ...errors, email: "Please enter your e-mail here!" });
      return false;
    }
    if (enquiry.email && !isValidEmail(enquiry.email)) {
      setErrors({ ...errors, email: "Please enter a valid email here!" });
      return false;
    }
    if (enquiry.preference === "phone" && !enquiry["phone"]) {
      setErrors({ ...errors, phone: "Please enter phone number!" });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!token) {
      setErrors({ ...errors, captcha: "Please verify you are not Robot!" });
      return;
    }
    let isValid = validateForm();
    if (!isValid) return;
    setIsLoading(false);

    addDoc(collection(firestore, HOST_EMAIL), {
      to: HOST_EMAIL,
      message: {
        subject: "Site Enquiry",
        text: "Hello ProtocolZone!",
        html: `Enquiry on Protocolzone  => ${JSON.stringify(enquiry)}`,
        cc: enquiry.email,
      },
    })
      .then(() => {
        setIsLoading(false);
        setFormResponse("SUCCESS");
      })
      .catch((err) => {
        console.log({ err });
        setIsLoading(false);
        setFormResponse("ERROR");
      });
  };

  const Input = (label: string, field: string, type?: string) => {
    return (
      <FormInput
        key={label}
        name={field ?? label.toLowerCase()}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        type={type ?? "text"}
        label={label}
        value={enquiry[field]}
        errors={errors[field]}
        setErrors={updateErrors}
        errorKey={field}
      />
    );
  };

  const PhoneInput = (field: string) => {
    return (
      <div className={styles.phoneInput}>
        <CountrySelect
          name="country"
          singleSelect
          label="Country"
          options={numCodes}
          value={enquiry.country}
          onChange={(name, val) => setEnquiry({ ...enquiry, country: val })}
        />
        <FormInput
          errorKey={field}
          name={field}
          value={enquiry[field]}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          errors={errors[field]}
          setErrors={updateErrors}
          type="text"
          label="Phone"
        />
      </div>
    );
  };

  const Preference = () => {
    return (
      <div className={styles.preferences}>
        <label>Preferred Method Of Communication</label>
        <div className={styles.radioWrapper}>
          <button
            onClick={() => handleChange("preference", "e-mail")}
            className={styles.radio}
          >
            <span className={styles.mark}>
              {enquiry.preference === "e-mail" && (
                <span className={styles.check}></span>
              )}
            </span>
            <label>E-mail</label>
          </button>
          <button
            onClick={() => handleChange("preference", "phone")}
            className={styles.radio}
          >
            <span className={styles.mark}>
              {enquiry.preference === "phone" && (
                <span className={styles.check}></span>
              )}
            </span>
            <label>Phone</label>
          </button>
        </div>
      </div>
    );
  };

  const verify = (e: any) => {
    setToken(e.response);
    let _errors = { ...errors };
    delete _errors["captcha"];
    setErrors(_errors);
  };

  const onExpire = () => {
    setToken(null);
  };

  const ThankYou = (
    <div className={styles.thanksScreen}>
      <div className={styles.thanksWrapper}>
        <h1>THANK YOU!</h1>
        <span>
          We appreciate your interest and our team will get back to you within
          48 working hours!
        </span>
      </div>
    </div>
  );

  return (
    <div className={styles.PageTalkToUsWrapper}>
      <Helmet>
        <title>Talk To Us - Protocol Zone </title>
        <link rel="canonical" href="https://www.protocolzone.com/talk-to-us" />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimization, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className={styles.textCol}>
          <h1 className={styles.head}>TALK WITH US</h1>
          <p className={styles.subHead}>
            Feel Like Contacting Us? Submit your queries here, and we will get
            back to you as soon as possible
          </p>
        </div>
      </AnimationOnScroll>

      <div className={styles.formCol}>
        {formResponse === "SUCCESS" ? (
          ThankYou
        ) : (
          <AnimationOnScroll animateIn="animate__zoomInUp">
            <div className={styles.formWrapper}>
              {isLoading && <div className={styles.formLoader}>Loading...</div>}
              <div className={styles.formTitle}>
                <span>Send us</span>
                <span> A Message</span>
              </div>
              <div className={styles.formBody}>
                {[
                  Input("Name", "name"),
                  Input("E-Mail", "email", "email"),
                  // Input("Phone", "phone", "tel"),
                ]}
                {PhoneInput("phone")}
                {/* <PhoneInput  onChange={(e) => handleChange(e.target.name, e.target.value)} type="text" placeholder="Enter Phone Number" /> */}
                {Preference()}
                <FormSelect
                  onChange={handleChange}
                  options={SERVICES}
                  label={"Preferred Service"}
                  name="Services"
                  isClear={isClear}
                />
                {Input("More Information", "moreInfo")}
                <div className={styles.captcha}>
                  {/* <GoogleReCaptcha onVerify={verify} /> */}
                  <Captcha
                    siteKey={CAPTCHA_SITEKEY}
                    onResponse={verify}
                    onExpire={onExpire}
                  ></Captcha>
                </div>
                <div className="text-center">
                  {errors["captcha"] && (
                    <span className={styles.error}>{errors["captcha"]}</span>
                  )}
                  <button onClick={handleSubmit} className={styles.submitBtn}>
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        )}
      </div>
    </div>
  );
};

export default PageTalkToUs;
