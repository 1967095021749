import { FC } from "react";
import styles from "../PageCustomerSupport.module.scss";
import check from "../../../assets/images/charSupport/check.png";
import { Child } from "../../../util/models/ui.model";

export default function VoiceCallSupport() {
  const Reason: FC<Child> = ({ children }) => {
    return (
      <span className={styles.reason}>
        <img src={check} alt="check" />
        <span className="pl-2">{children}</span>
      </span>
    );
  };

  return (
    <div className={`mTop5`}>
      <div className={"titleWrapper"}>
        <span className="left">Voice </span>
        <span>Call Support</span>
      </div>
      <div className="my2">
        <p style={{ textAlign: "justify" }}>
          Call centers are specialized divisions that manage large call volumes,
          acting as an extension of the business. We believe in offering
          all-inclusive calling solutions to sustain your inbound and outbound
          calling needs. All our call center agents possess impressive
          communication skills, problem-solving ability, organizational
          expertise, as well as knowledge retention, and recall power. This
          helps them handle all customer-related inquiries in a friendly and
          competent manner. We further conduct training sessions for our voice
          call agents in order to hone their talents and deliver the best
          solutions to our clients.
        </p>
      </div>
      <div className={styles.reasonsWrapper}>
        <h4 className={styles.title}>
          Other vital reasons to outsource your phone answering services are:
        </h4>
        <div>
          <Reason>
            By outsourcing the call center requirements, businesses can reduce
            major investments in facilities, equipment, operations, and
            staffing.
          </Reason>
          <Reason>
            External agents work more efficiently with dedicated managers
            overseeing the entire process, reducing costs-per-call.
          </Reason>
          <Reason>
            Managers deploy monitoring tools and performance assessment &
            improvement strategies in place to assure service level agreements
            (SLAs) are met.
          </Reason>
        </div>
      </div>
    </div>
  );
}
