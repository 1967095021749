import { CustomerSupportData } from "../../util/staticData/customerSupportData";
import EmailSupport from "./components/emailSupport";
import ServicesGridStatic from "./components/servicesGrid";
import VoiceCallSupport from "./components/voiceCallSupport";
import styles from "./PageCustomerSupport.module.scss";
import { Helmet } from "react-helmet";
export interface PageCustomerSupportProps {}

export const PageCustomerSupport = () => {
  const csData = { ...CustomerSupportData };

  const HeadPart = () => (
    <div className={styles.headDescription}>
      <div className={styles.col1}>
        <div className={"descImage"}>
          <img src={csData.img} alt="Description" />
        </div>
      </div>
      <div className={styles.col2}>
        <div className={"descriptionWrapper"}>
          <div className={"titleWrapper"}>
            <span>{csData.title.part1} &nbsp;</span>
            <span className={"right"}>{csData.title.part2}</span>
          </div>
          <div className={"description"}>
            {csData.descriptions.map((desc, index) => {
              return <p key={index}>{desc}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.PageCustomerSupport}>
      <Helmet>
        <title>24/7 Customer Support Service - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/customer-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={"landingImage"}>
        <img src={csData.coverImage} alt="Landing" />
        <h1>Customer Support</h1>
      </div>
      <HeadPart />
      <div className={styles.contentPart}>
        <div className={"titleWrapper"}>
          <span className="left">Live Chat </span>
          <span>&nbsp; {csData.title.part2}</span>
        </div>
        <div className={styles.solutionGrid}>
          {csData.solutions.map((sol, index) => (
            <div key={index} className={styles.solCard}>
              <p>{sol} </p>
            </div>
          ))}
        </div>
        <ServicesGridStatic />
        <VoiceCallSupport />
        <EmailSupport />
      </div>
    </div>
  );
};

export default PageCustomerSupport;
