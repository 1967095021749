import { EventTriggersData } from "../../util/staticData/eventTriggersData";
import EtsUseCases from "./components/ETSUseCases/ETSUseCases";
import TriggerUsage from "./components/TriggerUsage/TriggerUsage";
import styles from "./PageEventTriggers.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Helmet } from "react-helmet";

export interface PageEventTriggersProps {}

export const PageEventTriggers = (props: PageEventTriggersProps) => {
  const eData = { ...EventTriggersData };

  return (
    <div className={styles.PageEventTriggers}>
      <Helmet>
        <title>Event Trigger Service - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/event-trigger-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds horse racing,horse racing fixed, fixed odds betting horse racing, fixed odds horse betting, horse racing fixed odds betting, fixed odds racing, horse racing mobile app, horse racing analytics, horse racing strategies, horse racing probabilities, data events, racing pulses, horse racing status, live video switch, horse racing feed, horse racing bookmaker, propunter, event trader, betting, horse betting, sports betting, cyrpto trade, stock trade"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className={"landingImage"}>
        <img src={eData.coverImage} alt="Landing" />

        <h1>Event Trigger Service</h1>
      </div>
      <div className={styles.pageWrapper}>
        <div className={"headDescription"}>
          <AnimationOnScroll animateIn="animate__slideInDown">
            <div className={"col1 w-100"}>
              <div className={"descImage"} style={{ marginLeft: "50px" }}>
                <img src={eData.img} alt="Description" />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInDown">
            <div className={"col2 w-100"}>
              <div className={"descriptionWrapper"}>
                <div className={"titleWrapper"}>
                  <span>{eData.title.part1} &nbsp;</span>
                  <span className={"right"}>{eData.title.part2}</span>
                </div>
                <div className={"description"}>
                  {eData.descriptions.map((desc, index) => {
                    return <p key={index}>{desc}</p>;
                  })}
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
        <TriggerUsage />
        <EtsUseCases />
      </div>
    </div>
  );
};

export default PageEventTriggers;
