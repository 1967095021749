import { FC, useState } from "react";
import {
  TAB_HEAD,
  TeamMemberDTO,
  TEAM_DATA,
} from "../../util/staticData/ourTeamData";
import styles from "./PageOurTeam.module.scss";

const PageOurTeam: FC = () => {
  const [selectedTeam, setSelectedTeam] = useState("all");

  const MemberFlipCard = ({ member }: { member: TeamMemberDTO }) => {
    return (
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              src={require(`../../assets/images/team/${member.imgUrl}.png`)}
              alt=""
              className={styles.profileImage}
              // style={{ height: 300, width: 275 }}
            />
          </div>
          <div className="flip-card-back">
            <h1>{member.name}</h1>
            <p>{member.designation}</p>
            <p>Description</p>
          </div>
        </div>
      </div>
    );
  };

  const CategorizedMembers = () => (
    <div className={`${styles.teamCardWrapper}`}>
      {TEAM_DATA[selectedTeam]?.map((member) => {
        return (
          <div key={member.id} className={styles.teamCard}>
            <MemberFlipCard member={member} />
            <div className={styles.cardBody}>
              <h1 className={styles.name}>{member.name}</h1>
              <label className={styles.designation}>{member.designation}</label>
            </div>
          </div>
        );
      })}
    </div>
  );

  const AllMembers = () => (
    <div className="container">
      {Object.keys(TEAM_DATA).map((type) => {
        return (
          <div className={styles.teamWrapper}>
            <div className={styles.typeTitle}>
              <span>{type}</span>
            </div>
            <div className={styles.typeBody}>
              <div className={styles.profileCardsContainer}>
                {TEAM_DATA[type].map((member) => {
                  return (
                    <div key={member.id} className={styles.allMemberCard}>
                      <div className={styles.cardImage}>
                        <img
                          src={require(`../../assets/images/team/${member.imgUrl}.png`)}
                          alt=""
                        />
                      </div>
                      <div className={styles.cardBody}>
                        <div className={styles.name}>{member.name}</div>
                        <div className={styles.designation}>
                          {member.designation}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <section className={styles.pageWrapper}>
      <header className={styles.coverHeader}>
        <h1>OUR TEAM</h1>
        <footer className={styles.coverFooter}>
          <div className={styles.tabBtnWrapper}>
            {TAB_HEAD.map((tab) => {
              return (
                <button
                  onClick={() => setSelectedTeam(tab.field)}
                  className={`${styles.tabHeadBtn} ${
                    selectedTeam === tab.field && styles.activeTab
                  }`}
                  key={tab.field}
                >
                  {tab.label}
                </button>
              );
            })}
          </div>
        </footer>
      </header>
      <main>
        {selectedTeam === "all" ? <AllMembers /> : <CategorizedMembers />}
      </main>
    </section>
  );
};

export default PageOurTeam;
