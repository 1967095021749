import { EventTriggersData } from "../../../../util/staticData/eventTriggersData";
import styles from "./TriggerUsage.module.scss";

export interface TriggerUsageProps {}

export const TriggerUsage = () => {
  const eData = { ...EventTriggersData };

  return (
    <div className={styles.TriggerUsage}>
      <div className={styles.usageTitle}>
        <span>Event Trigger Service Can Be Used By</span>
      </div>
      <div className={styles.usageGrid}>
        {eData.triggerUsage.map((usage) => {
          return (
            <div key={usage.image} className={styles.usageCard}>
              <div className={styles.usageImage}>
                <img
                  src={require(`../../../../assets/images/EventTriggers/usages/${usage.image}`)}
                  alt={usage.image}
                />
              </div>
              <div className={styles.usage}>{usage.use}</div>
            </div>
          );
        })}
      </div>
      <div>
        <div className={`${styles.usageCard} ${styles.lg}`}>
          <div className={styles.usageImage}>
            <img
              src={require(`../../../../assets/images/EventTriggers/usages/videoSwitching.webp`)}
              alt=""
            />
          </div>
          <div className={`${styles.usage} ${styles.lg}`}>
            Video switching in real-time events for Advertisement or priority
            purpose
          </div>
        </div>
      </div>
    </div>
  );
};

export default TriggerUsage;
