import { ASSETS_URL_VIDEO } from "../constants/generalConstants";

export const HomeData = {
  videoUrls: {
    explain: ASSETS_URL_VIDEO + "pzoneHome.mp4",
    solution: ASSETS_URL_VIDEO + "yourSolution.mp4",
    data: ASSETS_URL_VIDEO + "yourData.mp4",
    loading: ASSETS_URL_VIDEO + "loading_bar.mp4",
  },
};
