import { ASSETS_URL_IMG } from "../constants/generalConstants";

export const CustomerSupportData = {
  coverImage: ASSETS_URL_IMG + "cs_cover.webp",
  img: ASSETS_URL_IMG + "cs_descriptionImage.webp",
  title: {
    part1: "24/7",
    part2: "Customer Support",
  },
  descriptions: [
    "Customers are often faced with the dilemma of where to go and whom to contact when they face issues. This is where strong customer support comes into play. Customer support is crucial for any organization to survive in this competitive market. Below-par customer service can negatively impact your brand , even when your product is of top-notch quality. It is crucial to deliver qualitative after-sales services to not only maintain steady customer relationships but build their confidence in your business.",
  ],
  // useCases: UseCases,
  solutions: [
    "ProtocolZone Pvt Ltd is one of the leading outsourcing companies with the aim of meeting our patrons’ A to Z live chat support requirements. As a service-oriented firm, we mainly focus on providing customized, cost-efficient, and globally recognized solutions in real-time.",
    "We provide a range of personalized live chat support solutions to meet your business prerequisites. Our skilled customer service executives receive sector-wide training to guarantee that they offer extraordinary value to each customer of yours. Whatever your specific needs are, we offer services for your organization including:",
  ],
};
