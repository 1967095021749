import { WhatWeDoSections } from "../../../../util/staticData/whatWeDoData";
import DynamicWhatWeDo from "./WhatWeDoSections";
import styles from "./WhatWeDo.module.scss";
const WhatWeDo = () => {
  return (
    <>
      <div className={styles.titleWrapper}>
        <span className={styles.titleX}>
          <span className={styles.titleY}>
            <div className={styles.title}>What We Do</div>
          </span>
        </span>
      </div>
      <div className={styles.whatWeDeoWrapper}>
        {WhatWeDoSections.map((section) => {
          return <DynamicWhatWeDo section={section} key={section.id} />;
        })}
      </div>
    </>
  );
};

export default WhatWeDo;
