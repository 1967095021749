// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  getToken,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: "AIzaSyCqUQO5iBxdqNucgb7YVGEsgdGJ7VWLfvM",
//   authDomain: "pzone-test-uk.firebaseapp.com",
//   projectId: "pzone-test-uk",
//   storageBucket: "pzone-test-uk.appspot.com",
//   messagingSenderId: "659740256185",
//   appId: "1:659740256185:web:84801e18d32916f60af868",
//   measurementId: "G-KCWHLEC5MS",
// };

const firebaseConfig = {
  apiKey: "AIzaSyColVmpsgXV_frzFzAQIhWBW3UNrL30lPI",
  authDomain: "pzone-site.firebaseapp.com",
  projectId: "pzone-site",
  storageBucket: "pzone-site.appspot.com",
  messagingSenderId: "393752569187",
  appId: "1:393752569187:web:dc66dca75e28e5935e9f56",
  measurementId: "G-Q2Q7TGYDKG",
};

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdGoJseAAAAAMBSYCyiZ5h5BAZWeTQFbGS7BxC8"),
  isTokenAutoRefreshEnabled: true,
});

getToken(appCheck).catch((error) => {
  console.log(error.message);
});

// Initialize Firebase
// const adminApp = InitAdmin(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
