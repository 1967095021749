import styles from "./PageAlgoTrading.module.scss";
import { Helmet } from "react-helmet";
import algoCoverImage from "../../assets/images/algoHeaderImage.jpg";
import { AlgoData } from "../../util/staticData/algoManageData";
import algoImage from "../../assets/images/algoImage.png";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { ASSETS_URL_IMG } from "../../util/constants/generalConstants";
import Carousel from "react-multi-carousel";
import AnkComingSoonAnimation from "../../components/ankComingSoonAnimation/ankComingSoonAnimation";
import { Link } from "react-router-dom";
import { ROUTES } from "../../util/constants/routerConstants";

export interface PageFixedOddsManageProps {}

export const PageAlgoTrading = () => {
  const HeadSection = (
    <div className={styles.coverHeading}>
      <h2>ALGO TRADING PLATFORM</h2>
    </div>
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 2560, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 590 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 590, min: 320 },
      items: 1,
    },
  };

  return (
    <div className={styles.pageAlgoTrading}>
      <Helmet>
        <title>Fixed Odds Management Service - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/fixed-odds-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className={styles.coverImageWrapper}>
        <img
          src={algoCoverImage}
          alt=""
          className={`${styles.coverImage} center`}
        />
        <div className={styles.overlay}></div>
        {HeadSection}
      </div>

      <div className={styles.pageWrapper}>
        {AlgoData.map((section, key) => {
          return (
            <div
              className={`${styles.msRow} ${styles[section.videoPos]}`}
              key={key}
            >
              <div className={`${styles.msCol} `}>
                <div className={styles.videoWrapper}>
                  {section.image ?? (
                    <img
                      src={algoImage}
                      alt="algoImage"
                      className={styles.sectionImage}
                    />
                  )}
                </div>
              </div>

              <div className={`${styles.msCol} `}>
                <div className={styles.contentWrapper}>
                  <div className={"titleWrapper"}>
                    <span
                      className={`${
                        section.contentPos === "left" && section.contentPos
                      }`}
                    >
                      {section.title.part1}
                    </span>
                    <span
                      className={`${
                        section.contentPos === "right" && section.contentPos
                      }`}
                    >
                      &nbsp;{section.title.part2}
                    </span>
                  </div>
                  <div className={styles.content}>
                    {section.content.map((text, index) => {
                      return <p key={index}>{text}</p>;
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.paraSection}>
        <div className={styles.slider}>
          <Carousel
            responsive={responsive}
            ssr={true}
            draggable={true}
            slidesToSlide={1}
            autoPlay={true}
            autoPlaySpeed={2000}
            transitionDuration={400}
            infinite={true}
            arrows={false}
            showDots={false}
            
          >
            <img
              src={ASSETS_URL_IMG + "slider_analysis.png"}
              alt="slider_analysis.png"
              className={styles.sliderImage}
            />

            <img
              src={ASSETS_URL_IMG + "slider_analysis_greeks.png"}
              alt="slider_analysis_greeks.png"
              className={styles.sliderImage}
            />
            <img
              src={ASSETS_URL_IMG + "slider_dashboard.png"}
              alt="slider_add_scripts.png"
              className={styles.sliderImage}
            />
            <img
              src={ASSETS_URL_IMG + "slider_add_strategy.png"}
              alt="slider_add_strategies.png"
              className={styles.sliderImage}
            />
            {/* </Slider> */}
          </Carousel>
        </div>
        <div className={styles.paraHeading}>
          <span className={styles.paraHeadingPart}>ANK-Algorithm</span> Trading
          Services
        </div>

        <div className={styles.headSection}>
          <AnimationOnScroll animateIn="animate__zoomIn">
            <div className={styles.headDescription}>
              Usually, one of the major obstacles that our industry clients had
              experienced is the time consumed in the development and deployment
              of new{" "}{/*  */}
              <span className={'highlight'}>trading strategies.</span> To
              overcome this problem we have recently launched an exclusive
              service called{" "}
              <span className={'highlight'}>"TRADE DevOps"</span>{" "}
              specifically designed for people involved in trading like {" "}
              {" "}<span className={'highlight'}>
                proprietary traders, fund managers,
              </span>{" "}
              and
              {" "}<span className={'highlight'}>big trading houses</span>. Trade
              DevOps is an experienced coder who works
              {" "}<span className={'highlight'}>onsite with your trading</span>{" "}
              team and will turn your innovative idea into authentic and
              deployable strategies. After the strategies have been created as
              per your requirement, you can simply do
              {" "}<span className={'highlight'}>backtesting</span>, and conduct
              a {" "}<span className={'highlight'}>live simulation</span>. Once
              you are satisfied with the performance of this distinctively
              created strategy and gained enough conviction you can make it
              {" "} <span className={'highlight'}>
                live with a single click in real time.
              </span>
              To know more about
              {" "} <span className={'highlight'}>
                Ank and Trade DevOps kindly
              </span>{" "}
              <Link className={styles.talkToUSLink} to={ROUTES.TALK_TO_US}>
                contact us
              </Link>{" "}
              .{/* </div> */}
            </div>
          </AnimationOnScroll>
        </div>
        <div className={styles.comingSoonText}>
          <AnkComingSoonAnimation />
        </div>
      </div>
    </div>
  );
};

export default PageAlgoTrading;
