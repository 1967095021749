export const EventCodeToName: { [type: string]: string } = {
  TH: "Thoroughbred",
  HS: "Harness",
  DO: "Greyhound",
};

export const SERVICES = [
  "Fixed Odds Service",
  "Event Trigger Service",
  "Wager Optimization Service",
  "Customer Support",
  "Data & Analytical Service",
  "Other",
];
