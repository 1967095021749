export interface NumbersDTO {
  countryCode: string;
  phoneCode: string;
  firstPart: string;
  lastPart: string;
  wholeNumber: string;
  id: number;
}
export const VirtualNumbers: NumbersDTO[] = [
  // {
  //   countryCode: "US",
  //   phoneCode: "+1",
  //   firstPart: "2398",
  //   lastPart: "802314",
  //   wholeNumber: "+12398802314",
  //   id: 1,
  // },
  // {
  //   countryCode: "GB",
  //   phoneCode: "+44",
  //   firstPart: "1415",
  //   lastPart: "360875",
  //   wholeNumber: "+441415360875",
  //   id: 2,
  // },
  // {
  //   countryCode: "AU",
  //   phoneCode: "+61",
  //   firstPart: "8747",
  //   lastPart: "77915",
  //   wholeNumber: "+61874777915",
  //   id: 3,
  // },
  {
    countryCode: "IN",
    phoneCode: "+91",
    firstPart: "7948",
    lastPart: "910535",
    wholeNumber: "+917948910535",
    id: 4,
  },
];
