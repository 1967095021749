import { FC } from "react";
import styles from "../PageCustomerSupport.module.scss";
import check from "../../../assets/images/charSupport/check.png";
import { Child } from "../../../util/models/ui.model";

export default function EmailSupport() {
  const ACard: FC<Child> = ({ children }) => {
    return (
      <div className={styles.aCard}>
        <img src={check} alt="check" />
        <p>{children}</p>
      </div>
    );
  };

  return (
    <div className={`mTop5`}>
      <div className={"titleWrapper"}>
        <span className="left">Email </span>
        <span>Support Services </span>
      </div>

      <div className="my2">
        <p style={{ textAlign: "justify" }}>
          Over the past few years, email communication has changed the dynamics
          of business across the globe. It plays a vital role in online
          businesses as this is the cheaper yet more professional way of
          communicating and responding to customer queries.
        </p>
      </div>

      <div className={styles.textGrid}>
        <p>
          Email responses are the part and parcel of each organization be it a
          start-up or a mid-sized company or a multinational corporation. At an
          initial level, the volume of email inquiries might be low and easy to
          manage but as soon as the business grows it’s tough for their
          employees to respond to each and every customer on time. This could
          result in an adverse impact of losing potential and even existing
          customers.
        </p>
        <p>
          If such things happen at your end, rather than panicking you can
          simply opt for outsourcing email support services. We at Protocolzone
          Pvt Ltd carry expertise in professional email writing services for
          various types of businesses. Our industry-wide experience in customer
          support makes us the best choice in offering such services that can
          lead your company to endure sales increase and customer satisfaction
          which ultimately leads to customer loyalty.
        </p>
      </div>
      <div className={styles.advWrapper}>
        <h6>
          Some more advantages of outsourcing ProtocolZone Email Support
          Service:
        </h6>

        <ACard>
          Customized and Cost-effective packages which can fulfill all your
          needs whether being a small enterprise or a large expanding business.
          We do offer pay per email or pay per project options depending on the
          requirements of the clientele.
        </ACard>

        <ACard>
          Our highly experienced and reliable executives will be answering
          various queries by your customers easily be it a delivery complaint or
          billing one and that too within a predefined TAT for 24 hours a day, 7
          days a week.
        </ACard>
        <ACard>
          We can proudly say that we can cater to a wide range of firms with a
          variety of industries. Our expertise lay in finance, sports, software,
          stock-market, health, real estate, and many more. Along with that, we
          can easily arrange the entire installation and maintenance of your
          systems so that your customer can reach you immediately.
        </ACard>
        <ACard>
          An email conversation is always preferred by the customer because it
          is a more private and secure option rather than questioning on social
          media and we ensure that all the information we gather from you and
          your customer will not be shared with anyone. Also, we constantly
          monitor our agent responses through our advanced monitoring mechanisms
          to improve and continue with our incomparable services.
        </ACard>
      </div>

      <div className={styles.note}>
        If you are still looking for the best option to outsource any of the
        above services offered, simply call us to get to know more about us and
        the best quote an industry can offer.
      </div>
    </div>
  );
}
