import { WagerOptimizationData } from "../../util/staticData/wagerOptimizationData";
import styles from "./PageWagerOptimization.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import check from "../../assets/images/charSupport/check.png";
import { Helmet } from "react-helmet";

export interface PageWagerOptimizationProps {}

export const PageWagerOptimization = (props: PageWagerOptimizationProps) => {
  const woData = { ...WagerOptimizationData };

  return (
    <div className={styles.PageWagerOptimization}>
      <Helmet>
        <title>Optimization Service - Protocol Zone </title>
        <link
          rel="canonical"
          href="https://www.protocolzone.com/optimization-service"
        />
        <meta
          name="description"
          content="Protocolzone is a premium IT company expert providing data-centric, real-time, and transactional software and infrastructure solutions for their complex business needs."
        />
        <meta
          name="keywords"
          content="fixed odds, horse racing, sports analytics, offshore support, betting automation, pulse service, investment optimisation, betting api, horse racing data, amtote, betfair, algo trading, event trigger"
        />
        <meta name="author" content="Protocol Zone Pvt. Ltd."></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className={"landingImage"}>
        <img src={woData.coverImage} alt="Landing" />
        <h1>Optimization Service</h1>
      </div>
      <AnimationOnScroll animateIn="animate__slideInDown">
        <div className={styles.headDescription}>
          <div className={styles.col1}>
            <div className={"descImage"}>
              <img src={woData.img} alt="Description" />
            </div>
          </div>
          <div className={styles.col2}>
            <div className={"descriptionWrapper"}>
              <div className={"titleWrapper"}>
                <span>{woData.title.part1} &nbsp;</span>
                <span className={"right"}>{woData.title.part2}</span>
              </div>
              <div className={"description"}>
                {woData.descriptions.map((desc, index) => {
                  return <p key={index}>{desc}</p>;
                })}
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeft">
        <div className={styles.applicablesWrapper}>
          <div className={"titleWrapper"}>
            <span className={"left"}>Successfully applied&nbsp;</span>
            <span> in the following areas </span>
          </div>
          {woData.applications.map((adv, index) => {
            return (
              <div key={index} className={styles.listContainer}>
                <img src={check} alt="check" />
                <span>{adv}</span>
              </div>
            );
          })}
        </div>
      </AnimationOnScroll>

      <div className={styles.useCasesWrapper}>
        {/* <div className={"descriptionWrapper"} style={{ width: "90%" }}>
          <div className={"titleWrapper"}>
            <span>Advantages Of &nbsp;</span>
            <span className={"right"}>Optimization Service </span>
          </div>
        </div> */}

        <div
          className={`${styles.solutionGrid} descriptionWrapper`}
          style={{ width: "90%" }}
        >
          {woData.advantages.map((adv, index) => {
            return (
              <AnimationOnScroll key={index} animateIn={adv.anim}>
                <div className={styles.solCard}>
                  <img
                    src={require(`../../assets/images/WagerOptimization/${adv.img}`)}
                    alt="advantage"
                    className={styles.advImg}
                  />
                  <p>{adv.data}</p>
                </div>
              </AnimationOnScroll>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PageWagerOptimization;
