import { FC, useEffect, useRef, useState } from "react";
import { FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import { VirtualNumbers } from "../../../../util/staticData/virtualNumbers";
import styles from "./VirtualCallUs.module.scss";

interface VirtualCallUsProps {
  textLight?: boolean;
}

export const VirtualCallUs: FC<VirtualCallUsProps> = ({ textLight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDocCLick = (e: any) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocCLick);

    return () => {
      document.removeEventListener("click", handleDocCLick);
    };
  }, []);

  const NumberOptions = VirtualNumbers.map((num) => {
    return (
      <div key={num.id} className={styles.numbers}>
        <span
          className={`ip2location-flag-32 flag-${
            num.countryCode?.toLowerCase() ?? "au"
          }`}
        ></span>
        <a href={`tel:${num.wholeNumber}`}>
          {num.phoneCode} {num.firstPart} {num.lastPart}{" "}
        </a>
      </div>
    );
  });

  return (
    <div>
      {/* <div className={styles.numbersWrapper}>{NumberOptions}</div> */}
      <div className={styles.phoneDropWrapper} ref={dropRef}>
        <button
          className={textLight ? styles.light : ""}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FaPhone />
        </button>
        <div className={styles.dropItemsWrapper}>
          <div className={styles.phoneDropItems}>{isOpen && NumberOptions}</div>
        </div>
      </div>
      <Link to="" />
    </div>
  );
};

export default VirtualCallUs;
