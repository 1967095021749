import { ASSETS_URL_IMG } from "../constants/generalConstants";
import { ROUTES } from "../constants/routerConstants";
import { WhatWeDoSectionsDTO } from "./whatWeDoData";
import algoImage from "../../assets/images/algoImage.png";

export interface ALGoDataDTO {
  id: number;
  title: {
    part1: string;
    part2: string;
  };
  videoPos: "left" | "right";
  contentPos: "left" | "right";
  content: string[];
  videoFileName: string;
  image?: any;
}

export const WhatWeDoSections: ALGoDataDTO[] = [
  {
    id: 1,
    content: [
      "People often tend to think that automated option trading can work as a money-making machine for them which is not practical in the first place. However, in simple terms, automated options trading is a method of trading options where the buying and selling decisions are made by a computer program. The program uses algorithms to analyze market data and determine the best trades to make based on pre-determined rules. It saves time and helps remove human emotion from the trading process. However, it is important to thoroughly test and backtest the program before deploying it live, as sometimes the chances of guaranteed success are less.",
      "Did you ever imagine a platform that gives you a comprehensive solution for all your needs related to algo trading? Yes, that’s possible! We offer you such an exclusively designed portal called Ank which is capable of trading through proven and tested strategies in your trading account. Basically, Ank is an automated trading software that runs on statistical and mathematical models which can give you better returns based on market indicators and movers.",
    ],
    title: {
      part1: "ANK -",
      part2: "ALGO TRADING PLATFORM",
    },
    videoFileName: "",
    videoPos: "left",
    contentPos: "right",
    image: algoImage,
  },
];

export const AlgoData: ALGoDataDTO[] = [
  {
    id: 1,
    content: [
      "People often tend to think that automated option trading can work as a money-making machine for them which is not practical in the first place. However, in simple terms, automated options trading is a method of trading options where the buying and selling decisions are made by a computer program. The program uses algorithms to analyze market data and determine the best trades to make based on pre-determined rules. It saves time and helps remove human emotion from the trading process. However, it is important to thoroughly test and backtest the program before deploying it live, as sometimes the chances of guaranteed success are less.",
      "Did you ever imagine a platform that gives you a comprehensive solution for all your needs related to algo trading? Yes, that’s possible! We offer you such an exclusively designed portal called Ank which is capable of trading through proven and tested strategies in your trading account. Basically, Ank is an automated trading software that runs on statistical and mathematical models which can give you better returns based on market indicators and movers.",
      "The platform originally delivers sustainability in terms of returns other than that it has many salient features that help traders in many ways such as option analytics, backtesting, Live simulation, strategy building, and live real-time algo trading via various brokers.",
    ],
    title: {
      part1: "ANK -",
      part2: "ALGO TRADING PLATFORM",
    },
    videoFileName: "",
    videoPos: "left",
    contentPos: "right",
  },
];
