import { FC, useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { MENU_LIST } from "../../util/staticData/menu";
import styles from "./Menu.module.scss";
import LOGO_DARK from "../../assets/images/pzlogoDark.svg";
import AccordionMenu from "./AccordionMenu/AccordionMenu";
import { ROUTES } from "../../util/constants/routerConstants";
import CascadeMenu from "./CascadeMenu/CascadeMenu";

interface MenuProps {
  textLight?: boolean;
}

const Menu: FC<MenuProps> = ({ textLight }) => {
  const { width } = useWindowSize();
  const [isSideOpen, setIsSideOpen] = useState(false);
  const isHome = window.location.pathname === ROUTES.HOME;

  useEffect(() => {
    if (width && width > 767) setIsSideOpen(false);
    return () => {};
  }, [width]);

  const DesktopMenu = () => {
    if (width >= 1366)
      return (
        <div className={`${styles.menuWrapper}`}>
          {MENU_LIST.map((menu) => {
            if (menu.id === 0 && isHome) return null;
            if (menu.options)
              return (
                <CascadeMenu
                  options={menu.options}
                  label={menu.label}
                  key={menu.id}
                  textLight={textLight}
                />
              );
            return menu.isLink ? (
              <a
                className={`${styles.menuLink} ${textLight && styles.light}`}
                key={menu.id}
                href={"https://protocolzone.in/blogs/"}
              >
                {menu.label}
              </a>
            ) : (
              <Link
                className={`${styles.menuLink} ${textLight && styles.light}`}
                key={menu.id}
                to={menu.href ?? ""}
              >
                {menu.label}
              </Link>
            );
          })}
        </div>
      );
    return null;
  };

  const MobileBar = () => {
    if (width >= 1366) return null;
    return (
      // <div className={styles.sidebarWrapper}>
      <>
        <button
          onClick={() => setIsSideOpen(!isSideOpen)}
          className={`${styles.bars} ${textLight && styles.light}`}
        >
          <FaBars />
        </button>
      </>
    );
  };

  const Sidebar = () => {
    if (isSideOpen)
      return (
        <div className={styles.sidebarWrapper}>
          <div
            className={styles.sidebarMask}
            onClick={() => setIsSideOpen(!isSideOpen)}
          ></div>
          <button
            onClick={() => setIsSideOpen(false)}
            className={styles.closeBtn}
          >
            <AiOutlineClose size="1.5rem" />
          </button>
          <div className={styles.sidebar}>
            <div className={styles.sidebarBody}>
              <Link to={ROUTES.HOME}>
                <div className={styles.sidebarLogo}>
                  <img src={LOGO_DARK} alt="LOGO" />
                </div>
              </Link>
              {MENU_LIST.map((menu) => {
                if (menu.options)
                  return (
                    <AccordionMenu
                      label={menu.label}
                      key={menu.id}
                      options={menu.options}
                      onClick={() => setIsSideOpen(false)}
                    />
                  );
                return menu.isLink ? (
                  <a
                    className={styles.sidebarMenuBtn}
                    key={menu.id}
                    href={"https://protocolzone.in/blogs/"}
                  >
                    {menu.label}
                  </a>
                ) : (
                  <Link
                    to={menu.href ?? ROUTES.HOME}
                    className={styles.sidebarMenuBtn}
                    key={menu.id}
                    onClick={() => setIsSideOpen(false)}
                  >
                    {menu.label}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      );
    return null;
  };

  return (
    <>
      <DesktopMenu />
      <MobileBar />
      <Sidebar />
    </>
  );
};

export default Menu;
