import { useEffect, useRef, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import styles from "./formSelect.module.scss";

interface FormSelectProps {
  title?: string;
  options: string[];
  label?: string;
  name?: string;
  isClear?: boolean;
  singleSelect?: boolean;
  onChange?: (name: string, value: string[]) => void;
}

export const FormSelect = (props: FormSelectProps) => {
  const [value, setValue] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef<any>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (props.isClear) {
      setValue([]);
    }

    return () => {};
  }, [props.isClear]);

  const handleFocus = (e: any) => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: string) => {
    let _val = [...value];
    if (!props.singleSelect) {
      let i = _val.indexOf(option);
      if (i === -1) {
        _val.push(option);
      } else {
        _val.splice(i, 1);
      }
    } else {
      _val = [option];
      setIsOpen(false);
    }
    setValue(_val);
    props.onChange && props.onChange(props.name ?? "", _val);
  };

  return (
    <div className={styles.formSelect}>
      <div ref={dropRef} className={styles.formSelectWrapper}>
        <button
          className={`${styles.selectBtn}`}
          // onFocus={handleFocus}
          onClick={handleFocus}
        >
          {value.length !== 0 ? (
            value.map((val, index) => (
              <span className={styles.val} key={index}>
                {val}{" "}
              </span>
            ))
          ) : (
            <span className={styles.placeholder}>{props.label}</span>
          )}
        </button>
        <div className={`${styles.optionsWrapper} ${isOpen && styles.show}`}>
          <div className={styles.options}>
            {props.options.map((option, index) => {
              return (
                <button
                  className={`${styles.link}`}
                  onClick={() => selectOption(option)}
                  key={index}
                >
                  <span>{option}</span>
                  {value.includes(option) && (
                    <span className={styles.check}>
                      <FaRegCheckCircle />
                    </span>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSelect;
