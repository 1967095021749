import { ASSETS_URL_VIDEO } from "../../../../util/constants/generalConstants";
import styles from "./ManagementSection.module.scss";

export const StaticManagementSection = () => {
  return (
    <>
      <div className={`${styles.msRow} ${styles.left}`}>
        <div className={`${styles.msCol} `}>
          <div className={styles.videoWrapper}>
            <div className={` `}>
              <video
                className={`${styles.video} ${styles.left}`}
                src={`${ASSETS_URL_VIDEO + "schedule.mp4"}`}
                autoPlay
                muted
                typeof="video/mp4"
                loop
              >
                <source
                  src={`${ASSETS_URL_VIDEO + "schedule.mp4"}`}
                  type="video/mp4"
                />
                Video is not supported!
              </video>
            </div>
            <div className={`${styles.videoBorder} ${styles.left}`}></div>
          </div>
        </div>

        <div className={`${styles.msCol} `}>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title1} ${"right"}`}>Schedule</span>
              <span className={styles.title2}>Management</span>
            </div>
            <div className={styles.content}>
              <p>
                The schedule management component allows the admin users to
                manage the racing schedule. Adding racing data, runners basic
                information and metadata, create tote and fixed odds pool,
                manage pool status and resulting races. This component is
                capable of replicating races and statutes from different data
                providers automatically and scheduling pools to be available for
                betting with scheduled time. It also automatically closes
                betting pools before the race jump.
              </p>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title1} ${"right"}`}>Risk</span>
              <span className={styles.title2}>Management</span>
            </div>
            <div className={styles.content}>
              <p>
                The risk management component is created to minimize liability
                risk for the bookie. The bookie can set exposure limits and the
                platform will automatically manage it. The platform is built
                with layoff features that automatically forward risk via
                connectors on tote or Betfair when required. The customer
                monitoring feature automatically tags each customer with labels
                to categorize them and manage behavior-based risk.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.msRow} ${styles.right}`}>
        <div className={`${styles.msCol} `}>
          <div className={styles.videoWrapper}>
            <div className={` `}>
              <video
                className={`${styles.video} ${styles.right}`}
                src={`${ASSETS_URL_VIDEO + "risk.mp4"}`}
                autoPlay
                muted
                typeof="video/mp4"
                loop
              >
                <source
                  src={`${ASSETS_URL_VIDEO + "risk.mp4"}`}
                  type="video/mp4"
                />
                Video is not supported!
              </video>
            </div>
            <div className={`${styles.videoBorder} ${styles.right}`}></div>
          </div>
        </div>

        <div className={`${styles.msCol} `}>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title2} ${"left"}`}>Ashva Odds</span>
              <span className={styles.title1}>and Probs</span>
            </div>
            <div className={styles.content}>
              <p>
                With years of experience, our data analysis team has built
                different mathematical models. Combining it with public
                sentiments and investments, we generate strategy probability for
                each selection. We provide this to our fixed odds customers to
                manage risk. There are multiple types of odds feed that we offer
                such as timed bound probs, maximum liability probs, minimum of
                all, or customizable based on your need.
              </p>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title2} ${"left"}`}>Ashva race</span>
              <span className={styles.title1}> and runner statistics</span>
            </div>
            <div className={styles.content}>
              <p>
                We provide unique statistics of races and runners. We cover more
                probability-based factors such as track bias, real-time tote
                investment factors, odds sentiments, velocities, and combination
                factors. This gives the edge to punters to bet on more exotic
                pools. This service is offered as responsive widgets that are
                easy to integrate on frontends. Information will be fetched in
                real time to customers seamlessly.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.msRow} ${styles.left}`}>
        <div className={`${styles.msCol} `}>
          <div className={styles.videoWrapper}>
            <div className={` `}>
              <video
                className={`${styles.video} ${styles.left}`}
                src={`${ASSETS_URL_VIDEO + "white_label.mp4"}`}
                autoPlay
                muted
                typeof="video/mp4"
                loop
              >
                <source
                  src={`${ASSETS_URL_VIDEO + "white_label.mp4"}`}
                  type="video/mp4"
                />
                Video is not supported!
              </video>
            </div>
            <div className={`${styles.videoBorder} ${styles.left}`}></div>
          </div>
        </div>

        <div className={`${styles.msCol} `}>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title1} ${"right"}`}>
                Ashva Betting
              </span>
              <span className={styles.title2}> automation</span>
            </div>
            <div className={styles.content}>
              <p>
                Our innovative team has built expressions based on natural
                language. Customers can use this widget to write simple or
                complex boolean or mathematical expressions and perform
                operations to make decisions. For example, wager on favorite
                exacta selection in one minute to post time, trifecta with the
                first leg as a fav, and random long shots, the maximum sum of
                velocities factors to wager in the minute to post.
              </p>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title1} ${"right"}`}>
                White Labeled
              </span>
              <span className={styles.title2}> Front-End</span>
            </div>
            <div className={styles.content}>
              <p>
                We have built easy-to-integrate white-labeled components that
                will fit with your existing site with a seamless experience. We
                can provide you with zero deployment solutions with full
                confidence and security for your data and customers. With the
                cloud nature of our platform, you can focus on business and we
                will manage the technology
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.msRow} ${styles.right}`}>
        <div className={`${styles.msCol} `}>
          <div className={styles.videoWrapper}>
            <div className={` `}>
              <video
                className={`${styles.video} ${styles.right}`}
                src={`${ASSETS_URL_VIDEO + "ashvaRacing_runnerStates.mp4"}`}
                autoPlay
                muted
                typeof="video/mp4"
                loop
              >
                <source
                  src={`${ASSETS_URL_VIDEO + "ashvaRacing_runnerStates.mp4"}`}
                  type="video/mp4"
                />
                Video is not supported!
              </video>
            </div>
            <div className={`${styles.videoBorder} ${styles.right}`}></div>
          </div>
        </div>

        <div className={`${styles.msCol} `}>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title2} ${"left"}`}>
                Ashva aggregator
              </span>
              <span className={styles.title1}>and operator management</span>
            </div>
            <div className={styles.content}>
              <p>
                Ashva platform is capable of supporting different customer
                currencies and locales. This enables the Ashva platform to be
                used in multiple regions with the flexibility of running a
                single racing pool from the backend. Ashva platform has the
                capability of creating multiple aggregators and operators under
                it and routing live traffic as per the requirements
              </p>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={"titleWrapper"}>
              <span className={`${styles.title2} ${"left"}`}>
                Ingress and egress
              </span>
              <span className={styles.title1}>data management</span>
            </div>
            <div className={styles.content}>
              <p>
                Ashva platform has open APIs that support both push and pull
                methods. We have already created many connectors for the Ashva
                Platform to get live data feeds from multiple regions across the
                globe. Similarly to that, we do have multiple egress connectors
                to route betting traffic based on strategy and risk coverage. We
                can also add new connectors as per your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticManagementSection;
