import styles from "./WhoWeAre.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
const WhoWeAre = () => {
  return (
    <div className={styles.whoWeAreWrapper}>
      <div className={styles.imagePanel}></div>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className={styles.textPanel}>
          <div className={styles.headText}>
            <h2>The zone of excellence</h2>
            <h1>ProtocolZone</h1>
          </div>
          <div>
            <p>
              We are a premium IT company that delivers truly outstanding
              products and solutions. We provide our customers with data-centric
              solutions such as data cleaning, data visualization, data
              modeling, data forecasting and data reporting. Data is a new
              hidden asset that helps businesses to learn about new information
              such as threats, demand, supply, and productivity. We have the
              experience and capability to handle your large data in real-time
              helping you to achieve better outcomes. Our aim is to improve
              productivity, performance and reliability in your business and
              reduce overhead.
            </p>
            <p>
              Protocolzone has worked across different domains with a major
              focus on horse racing and algorithm-based trading. Our customer
              support team provides live event triggers to our global customer
              base to achieve automation and real-time decision-making. We have
              a handpicked team of professionals who always believe any task can
              be done in smarter, simple, and innovative ways.
            </p>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default WhoWeAre;
