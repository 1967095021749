import styles from "./FeaturesGrid.module.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { ASSETS_URL_IMG } from "../../../../util/constants/generalConstants";

const FeaturesGrid = () => {
  return (
    <div className={styles.featuresGrid}>
      <AnimationOnScroll animateIn="animate__fadeInUpBig">
        <div className={styles.gridCard}>
          <div className={styles.gridHeadImages}>
            <img
              src={ASSETS_URL_IMG + "feature_reliable.webp"}
              alt="Reliable"
            />
          </div>
          <div className={styles.gridText}>
            <h1 className={styles.textHead}>Reliable</h1>
            <p>
              Protocolzone is a renowned offshore company. We believe in
              building and maintaining long term relationships with all our
              clients.
            </p>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUpBig">
        <div className={styles.gridCard}>
          <div className={styles.gridHeadImages}>
            <img
              src={ASSETS_URL_IMG + "new_feature_affordable.png"}
              alt="Affordable"
            />
          </div>
          <div className={styles.gridText}>
            <h1 className={styles.textHead}>Valuable</h1>
            <p>
              We have provided valuable and effective development services to
              numerous large as well as medium size entrepreneurs.
            </p>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInUpBig">
        <div className={styles.gridCard}>
          <div className={styles.gridHeadImages}>
            <img
              src={ASSETS_URL_IMG + "feature_solutions.webp"}
              alt="Solutions"
            />
          </div>
          <div className={styles.gridText}>
            <h1 className={styles.textHead}>Innovative</h1>
            <p>
              We are pioneers in lambasting problems like problem-solving,
              decision making, forecasting, etc. Our experts handle your
              assigned projects prudently.
            </p>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default FeaturesGrid;
