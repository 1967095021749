import { Link } from "react-router-dom";
import Menu from "../../Menu/Menu";
import styles from "./PzFixedHeader.module.scss";
import PzLogo from "../../../assets/images/pzlogoLight.svg";
import VirtualCallUs from "../PzHeader/VirtualCallUs/VirtualCallUs";
import { ROUTES } from "../../../util/constants/routerConstants";

export const PzFixedHeader = () => {
  return (
    <div className={styles.fixedHeader}>
      <div className={styles.header}>
        <Link to={ROUTES.HOME}>
          <div className={`${styles.logoWrapper} `}>
            <img className={styles.image} src={PzLogo} alt="LOGO" />
          </div>
        </Link>
        <span className="d-flex align-items-center">
          <Menu textLight />
          <VirtualCallUs textLight />
        </span>
      </div>
    </div>
  );
};

export default PzFixedHeader;
